import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { DropDownFilterOptionDto } from "src/app/shared/models/dashboards/DropDownFilterOptionDto.model";
import { CheckboxState } from "src/app/shared/models/checkboxState.model";
import { MatAccordion } from "@angular/material/expansion";

@Component({
  selector: 'app-grouped-multi-select-filter',
  templateUrl: './filter-grouped-multiselect.component.html',
  styleUrls: ['./filter-grouped-multiselect.component.scss']
})
export class FilterGroupedMultiselectComponent  implements OnInit, AfterViewInit, OnDestroy {

	@Input() label: string = "Default Label";
	@Input() placeholder: string = "Default Placeholder";
	@Input() allowMultiple: boolean = true;
	@Input() options: DropDownFilterOptionDto[] = [];
	@Input() disabled: boolean = false;
	@Input() selection!: number[];

	@Output() selectionChange = new EventEmitter<number[]>();

	/** control for the selected values for multi-selection */
	public MultiCtrl: UntypedFormControl = new UntypedFormControl();

	/** control for the MatSelect filter keyword multi-selection */
	public MultiFilterCtrl: UntypedFormControl = new UntypedFormControl();

	/** list filtered by search keyword */
	public filteredMulti = new ReplaySubject<DropDownFilterOptionDto[]>(1);

	public filteredOptionGroups = new ReplaySubject<string[]>();
	public groupCheckboxStates: CheckboxState[];

	/** local copy of filtered list to help set the toggle all checkbox state */
	protected filteredCache: DropDownFilterOptionDto[] = [];

	/** flags to set the toggle all checkbox state */
	isIndeterminate = false;
	isChecked = true;

	@ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;
	@ViewChild('accordion', { static: true }) accordion: MatAccordion;

	/** Subject that emits when the component has been destroyed. */
	protected _onDestroy = new Subject<void>();

	/**
	 * Set the current selection based on array of identifiers
	 * (If empty array - all will be selected)
	 * */
	setSelection(incomingSelection: number[]) {
		if(incomingSelection && this.options && incomingSelection.length === 0){
			incomingSelection = this.options.map(x => x.id);
		}
		this.selection = incomingSelection;
		const groupCheckboxStates = this.options
			.map(x => x.group)
			.filter((value, index, array) => array.indexOf(value) === index)
		.map<CheckboxState>(x => ({name: x, isChecked: true, isIndeterminate: false}));
		let initialObjectSelection : DropDownFilterOptionDto[] = [];
		this.options.forEach(x => {
			if (incomingSelection.includes(x.id)){
				initialObjectSelection.push(x);
			}
		});

		this.groupCheckboxStates = groupCheckboxStates;
		this.updateSelection(initialObjectSelection, false);
	}

	ngOnInit() {
		if(!this.options){
			return;
		}
		this.setSelection(this.selection);

		// load the initial bank list
		this.filterMulti();
		this.setToggleAllCheckboxState();
		this.setGroupCheckboxStates();

		// listen for search field value changes
		this.MultiFilterCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterMulti();
				this.setToggleAllCheckboxState();
				this.setGroupCheckboxStates();
			});

		// listen for multi select field value changes
		this.MultiCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.setToggleAllCheckboxState();
				this.setGroupCheckboxStates();
			});

		this.filteredMulti.pipe(takeUntil(this._onDestroy)).subscribe(options => {
			const groups = options
				.map(x => x.group)
				.filter((value, index, array) => array.indexOf(value) === index)
				.sort((a: string, b: string) => {
					a = a?.toLowerCase();
					b = b?.toLowerCase();
					if (a === b) {
						return 0;
					}
					if (!a) {
						return -1;
					}
					if (!b) {
						return 1;
					}
					return a < b ? -1 : 1;
				});
			this.filteredOptionGroups.next(groups);
		});
	}

	ngAfterViewInit() {
		this.setInitialValue();
	}

	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}

	openedChange(opened: boolean) {
		if(!opened) {
			// - drop down closed
			this.accordion.closeAll();
			if(this.MultiCtrl.valid){
				let currentSelection = this.MultiCtrl.value.map(x => x.id);

				this.selection = currentSelection;
				this.selectionChange.emit(this.selection);
			}
		}
	}

	getGroupState(groupName: string): CheckboxState {
		return this.groupCheckboxStates.find(x => x.name == groupName);
	}

	/** Select All Toggle */
	toggleSelectAll(selectAllValue) {
		this.filteredMulti.pipe(take(1), takeUntil(this._onDestroy))
			.subscribe(val => {
				if (selectAllValue) {
					this.MultiCtrl.patchValue(val);
				} else {
					this.MultiCtrl.patchValue([]);
				}
			});
	}

	toggleSelectAllGroup(checked: boolean, group: string): void {
		this.filteredMulti.pipe(take(1), takeUntil(this._onDestroy))
			.subscribe(options => {
				const groupOptions = options.filter((option) => option.group === group);
				const newValue: DropDownFilterOptionDto[] = [...this.MultiCtrl.value];
				if (checked) {
					groupOptions.filter((option) => newValue.findIndex(y => y.id === option.id) < 0).forEach((option) => {
						newValue.push(option);
					});
				} else {
					groupOptions.forEach((option) => {
						const index = newValue.findIndex(y => y.id === option.id);
						if (index >= 0) {
							newValue.splice(index, 1);
						}
					});
				}
				this.MultiCtrl.patchValue(newValue);
			});
	}

	updateSelection(options: DropDownFilterOptionDto[], emitChangeEvent: boolean) {
		this.MultiCtrl.setValue(options);

		if(emitChangeEvent)
		{
			var multiFilter = this.MultiCtrl.value;
			// get selected filter value
			let selectedValue = multiFilter.filter(item => item.id == options[0].id);

			var filterArrLength = this.MultiCtrl.value.length;

			for(let index=0 ; index <  filterArrLength ; index++)
			{
				this.MultiCtrl.value.splice(0,1);
			}

			// set selected filter value in multi filter
			this.MultiCtrl.setValue(selectedValue);
			this.selectionChange.emit(this.MultiCtrl.value(x => x.Id));
		}
	}

	selectOption(option: DropDownFilterOptionDto) {
		const newValue: DropDownFilterOptionDto[] = [...this.MultiCtrl.value];
		const index = newValue.findIndex(x => x.id === option.id);
		if (index >= 0) {
			newValue.splice(index, 1);
		} else {
			newValue.push(option);
		}

		this.MultiCtrl.patchValue(newValue);
	}


	/** Select Only This Button Click */
	selectOnlyThis(event: MouseEvent, option: DropDownFilterOptionDto){
	    event.stopPropagation();
	    this.updateSelection([option], true);
	}


	/** Sets the initial value of hte multi-select */
	protected setInitialValue() {
		this.filteredMulti
			.pipe(take(1), takeUntil(this._onDestroy))
			.subscribe(() => {
				// setting the compareWith property to a comparison function
				// triggers initializing the selection according to the initial value of
				// the form control (i.e. _initializeSelection())
				// this needs to be done after the options are loaded initially
				// and after the mat-option elements are available
				this.multiSelect.compareWith = (a: DropDownFilterOptionDto, b: DropDownFilterOptionDto) => a && b && a.id === b.id;
			});
	}

	protected filterMulti() {
		if (!this.options) {
			return;
		}
		// get the search keyword
		let search = this.MultiFilterCtrl.value;
		if (!search) {
			this.filteredCache = this.options.slice();
			this.filteredMulti.next(this.filteredCache);
			return;
		} else {
			search = search.toLowerCase();
		}
		// filter the options
		this.filteredCache = this.options.filter(option => option.name.toLowerCase().indexOf(search) > -1);
		this.filteredMulti.next(this.filteredCache);
	}

	protected setToggleAllCheckboxState() {
		let filteredLength = 0;
		if (this.MultiCtrl && this.MultiCtrl.value) {
			this.filteredCache.forEach(el => {
				if (this.MultiCtrl.value.indexOf(el) > -1) {
					filteredLength++;
				}
			});
			this.isIndeterminate = filteredLength > 0 && filteredLength < this.filteredCache.length;
			this.isChecked = filteredLength > 0 && filteredLength === this.filteredCache.length;
		}
	}

	protected setGroupCheckboxStates() {
		this.groupCheckboxStates.forEach(state => {
			const cacheLength = this.filteredCache.filter(option => option.group == state.name).length;
			const selectedLength = this.MultiCtrl.value.filter(option => option.group == state.name).length;
			state.isChecked = selectedLength > 0 && selectedLength === cacheLength;
			state.isIndeterminate = selectedLength > 0 && selectedLength < cacheLength;
		});
	}
}
