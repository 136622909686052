<header>
    <h2 class="d-flex gap-2 align-items-center">
        <mat-icon>place</mat-icon>
        <span>{{ data.title }}</span>
    </h2>
    <button
        mat-icon-button
        aria-label="close dialog"
        disableRipple
        mat-dialog-close
        matTooltip="Close"
        [matTooltipShowDelay]="500"
        tabindex="-1">
        <mat-icon>close</mat-icon>
    </button>
</header>
<content>
    <ng-container *ngComponentOutlet="mapComponent; inputs: mapInput"></ng-container>
    <div class="map-filters" *ngIf="data.mapData.filters.length > 0 && mapLoaded">
        <mat-button-toggle-group
            *ngFor="let filter of data.mapData.filters"
            [value]="filter.selection.toString()"
            (change)="updateFilter(filter, $event)">
            <mat-button-toggle *ngFor="let item of filter.filterOptions | keyvalue" [value]="item.key.toString()">
                {{ item.value }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</content>
