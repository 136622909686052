import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
	DashboardsServiceV2,
	WidgetDataDTO,
	WidgetDataSetRequestDTO,
	WidgetDefinitionDTO,
} from 'src/app/core/services/dashboardsv2.service';

@Component({
	selector: 'app-widget-stats',
	templateUrl: './widget-stats.component.html',
	styleUrls: ['./widget-stats.component.scss'],
	providers: [DashboardsServiceV2],
})
export class StatsWidgetComponent implements OnChanges {
	@Input() widgetConfig: WidgetDefinitionDTO;
	@Input() dataSetRequest: WidgetDataSetRequestDTO;
	@Input() loadWidgetData: boolean;

	httpClientService: HttpClient;
	domSanitiserService: DomSanitizer;
	dashboardService: DashboardsServiceV2;

	widgetData: WidgetDataDTO;
	subscription: any;
	isLoaded: boolean;

	isPrimaryOnly: boolean;

	constructor(httpClient: HttpClient, sanitizer: DomSanitizer, dashboardService: DashboardsServiceV2) {
		this.httpClientService = httpClient;
		this.domSanitiserService = sanitizer;
		this.dashboardService = dashboardService;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.dataSetRequest && !changes.dataSetRequest.isFirstChange()) {
			if (this.loadWidgetData) {
				this.loadData();
			} else {
				this.isLoaded = false;
			}
		}

		if (changes.loadWidgetData && !this.isLoaded) {
			if (changes.loadWidgetData.currentValue) {
				this.loadData();
			}
			//stop loading unfinished widget
			if (!changes.loadWidgetData.currentValue && this.subscription) {
				this.subscription.unsubscribe();
			}
		}
	}

	loadData() {
		this.widgetConfig.loading = true;
		this.isLoaded = false;

		if (this.subscription) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.dashboardService
			.getDataForWidget(this.widgetConfig.dataEndpoint, this.dataSetRequest)
			.subscribe((data: WidgetDataDTO) => {
				this.widgetData = data;
				this.widgetConfig.loading = false;
				this.isLoaded = true;

				this.isPrimaryOnly = data.data.some((x) => x.length > 2);
			});
	}

	formatString(label: string): SafeHtml {
		return this.domSanitiserService.bypassSecurityTrustHtml(label.replace('\n', '<br/>'));
	}
}
