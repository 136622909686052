import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DashboardExport } from './dashboard-export';

@Component({
    selector: 'app-export',
    templateUrl: './export.component.html',
    styleUrl: './export.component.scss',
    encapsulation: ViewEncapsulation.None,
})
export class ExportComponent implements OnInit {
    public export: DashboardExport;

    constructor() {
    }

    ngOnInit(): void {
        this.export = JSON.parse(localStorage.getItem('dashboard-export'));
    }

    print(): void {
        window.print();
    }
}
