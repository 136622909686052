import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from 'src/app/configs/config';
import { MovementGroupDto, MovementMonitoringItemDto, QuadkeysDto } from 'src/app/shared/models/movement/';
import { MovementExportDto } from 'src/app/shared/models/movement/movementExportDto';

@Injectable({
	providedIn: 'root',
})
export class MovementService {
	// -- service api base url
	serviceBaseUrl = Api.baseApiUrl + '/api/v1/movement';

	constructor(public httpService: HttpClient) {}

	/** Return the quadkeys for a given NID Site */
	getQuadkeysForNidSite(siteId: number): Observable<QuadkeysDto> {
		return this.httpService.get<QuadkeysDto>(this.serviceBaseUrl + '/quadkeysForNidSite/' + siteId.toString());
	}

	/** Set the quadkeys for a given NID Site */
	setQuadkeysForNidSite(data: QuadkeysDto): Observable<any> {
		return this.httpService.post(this.serviceBaseUrl + '/quadkeysForNidSite', data);
	}

	/** Add/edit a NID site to the list of sites being monitored for movement data */
	editMonitoredNidSite(data: MovementMonitoringItemDto): Observable<any> {
		return this.httpService.post(this.serviceBaseUrl + '/monitoredNidSite', data);
	}

	/** Remove a NID site to the list of sites being monitored for movement data */
	removeMonitoredNidSite(siteId: number): Observable<any> {
		return this.httpService.delete(this.serviceBaseUrl + '/monitoredNidSite/' + siteId.toString());
	}

	/** Get ths list of quadkey collections and NID sites for an organisation */
	getMovementMonitoring(): Observable<MovementMonitoringItemDto[]> {
		return this.httpService.get<MovementMonitoringItemDto[]>(this.serviceBaseUrl + '/movementMonitoring');
	}

	/** Get the quadkeys for a give collection id */
	getQuadkeyCollection(id: number): Observable<QuadkeysDto> {
		return this.httpService.get<QuadkeysDto>(this.serviceBaseUrl + '/quadkeyCollection/' + id.toString());
	}

	/** Add/Edit a quadkey collection */
	editQuadkeyCollection(data: MovementMonitoringItemDto): Observable<number> {
		return this.httpService.post<number>(this.serviceBaseUrl + '/editQuadkeyCollection', data);
	}

	/** Delete a quadkey collection */
	deleteQuadkeyCollection(id: number): Observable<any> {
		return this.httpService.delete(this.serviceBaseUrl + '/deleteQuadkeyCollection/' + id.toString());
	}

	createMovementExport(): Observable<MovementExportDto> {
		return this.httpService.post<MovementExportDto>(this.serviceBaseUrl + '/movementExport', {});
	}

	getMovementExports(): Observable<MovementExportDto[]> {
		return this.httpService.get<MovementExportDto[]>(this.serviceBaseUrl + '/movementExports');
	}

	getMovementExport(id: number): Observable<MovementExportDto> {
		return this.httpService.get<MovementExportDto>(this.serviceBaseUrl + `/movementExport/${id}`);
	}

	getMovementExportCsv(id: number): Observable<Blob> {
		return this.httpService.get(this.serviceBaseUrl + `/movementExport/${id}/download`, {
			responseType: 'blob',
		});
	}

	cancelMovementExport(id: number): Observable<any> {
		return this.httpService.delete(this.serviceBaseUrl + `/movementExport/${id}`, {});
	}

	setQuadkeysForCollection(data: QuadkeysDto): Observable<any> {
		return this.httpService.post(this.serviceBaseUrl + '/quadkeysForCollection', data);
	}

	getMovementGroups(): Observable<MovementGroupDto[]> {
		return this.httpService.get<MovementGroupDto[]>(this.serviceBaseUrl + '/movementGroups');
	}

	updateMovementGroups(groups: { id: number; name: string }[]): Observable<any> {
		return this.httpService.post(this.serviceBaseUrl + '/movementGroups', groups);
	}
}
