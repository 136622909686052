import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
    @Input() text: string = '';
    @Input() icon: string = '';
    @Input() size: string = '';
    @Input() color: string = 'green';
    @Input() type: string = 'button';
    @Input() showProgress: boolean = false;
    @Input() disabled: boolean = false;
    @Input() showDropDown: boolean = false;

    @Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();

    /** Custom Class to added to button */
    bemModifierClasses(): string {
        let result: string = '';

        if (this.size.length > 0) result += ' seBtn--' + this.size;
        if (this.color.length > 0) result += ' seBtn--' + this.color;

        return result;
    }

    btnClicked() {
        this.buttonClicked.emit('Button Clicked');
    }
}
