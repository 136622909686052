<section class="loginForm">
	<!-- Login Form-->
	<form [formGroup]="form" (ngSubmit)="onSubmit()" class="loginShellForm">
		<div class="mb-5">
			<label for="username">EMAIL ADDRESS :</label>
			<input type="email" id="username" class="form-control" formControlName="username" />
		</div>

		<div class="mb-5">
			<label for="password">PASSWORD :</label>
			<input type="password" id="password" class="form-control" formControlName="password" />
		</div>

		<div class="d-flex justify-content-between align-items-center">
			<app-icon-button [icon]="'login'" [text]="'LOGIN'" [size]="'large'" [type]="'submit'" [showProgress]="loading">
			</app-icon-button>

			<a [routerLink]="['/login/password-forgot']">Forgotten password?</a>
		</div>
	</form>
	<p class="link-text">
		By logging in you agree to our <a href="{{ termsOfUseUrl }}" target="_blank">latest terms of use.</a>
	</p>
	<!-- New to Sports Eye -->
	<div class="newToSportsEye">
		<div class="newToSportsEye__box">
			<span>Register here</span>
		</div>
		<p class="newToSportsEye__text">
			Unlock the potential of your data. &nbsp;
			<a *ngIf="!isCpra" class="" href="{{ registerUrl }}" target="_blank"> Click here to register for an account. </a>
			<a *ngIf="isCpra" class="" href="https://activexchange.com/canadian-infrastructure-database/sign-up" target="_blank"> Click here to register for an account. </a>
		</p>
	</div>

	<!-- Partner Logos Per Theme -->
	<div class="theme__images--partnerLogoLogin"></div>
</section>
