<form [formGroup]="form" (ngSubmit)="onSubmit(passwordComponent.strength)">
	<div class="container-fluid">
		<div class="row justify-content-end mb-3 button-row">
			<app-icon-button
				icon="check_circle"
				text="UPDATE PASSWORD"
				size="small"
				class="col-auto"
				type="submit"
				[disabled]="!form.dirty || !form.valid"></app-icon-button>
		</div>
		<div class="row">
			<mat-form-field class="col" appearance="fill">
				<mat-label>Current Password</mat-label>
				<input matInput #pwdOld type="password" formControlName="oldPassword" required autocomplete="off" />
				<mat-error>Please provide current password</mat-error>
			</mat-form-field>
		</div>
		<div class="row">
			<mat-form-field class="col" appearance="fill">
				<mat-label>New Password</mat-label>
				<input
					matInput
					#pwd
					type="password"
					placeholder="Password"
					formControlName="password"
					required
					autocomplete="new-password" />
				<mat-error>Please provide a valid password</mat-error>
			</mat-form-field>
		</div>
		<div class="row">
			<mat-form-field class="col" appearance="fill">
				<mat-label>Confirm New Password</mat-label>
				<input matInput type="password" formControlName="confirmpassword" required autocomplete="new-password" />
				<mat-error> Password does not match</mat-error>
			</mat-form-field>
		</div>
		<div class="row">
			<div class="col" [hidden]="pwd.value.length <= 3">
				<mat-hint class="small">Password strength:</mat-hint>
				<span *ngIf="!matchedPolicy" class="small error ms-1">Password does not meet the minimum requirements</span>
				<app-password-strength
					#passwordComponent
					[password]="pwd.value"
					(strengthChanged)="onStrengthChanged($event)"></app-password-strength>
				<app-password-strength-info [passwordComponent]="passwordComponent"></app-password-strength-info>
			</div>
		</div>
		<div class="row">
			<mat-hint class="col">
				Note: Changing your password will sign you out of all other devices you are currently logged into.
			</mat-hint>
		</div>
	</div>
</form>
