import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';

export interface MultiSelectIdValue {
    id: number;
    name: string;
    parentIds?: number[];
}

@Component({
    selector: 'app-multi-select-filter',
    templateUrl: './filter-multiselect.component.html',
    styleUrls: ['./filter-multiselect.component.scss'],
})
export class MultiSelectFilterComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() label: string = 'Default Label';
    @Input() placeholder: string = 'Default Placeholder';
    @Input() allowMultiple: boolean = true;
    @Input() options: MultiSelectIdValue[] = [];
    @Input() disabled: boolean = false;
    @Input() minimumSelection: number = 1;
    @Input() minimumSelectionExclusions: number[] = [];

    @Input() selection!: number[];
    @Output() selectionChange = new EventEmitter<number[]>();

    /** control for the selected values for multi-selection */
    public MultiCtrl: UntypedFormControl = new UntypedFormControl();

    /** control for the MatSelect filter keyword multi-selection */
    public MultiFilterCtrl: UntypedFormControl = new UntypedFormControl();

    /** list filtered by search keyword */
    public filteredMulti: ReplaySubject<MultiSelectIdValue[]> = new ReplaySubject<MultiSelectIdValue[]>(1);

    /** local copy of filtered list to help set the toggle all checkbox state */
    protected filteredCache: MultiSelectIdValue[] = [];

    /** flags to set the toggle all checkbox state */
    isIndeterminate = false;
    isChecked = false;

    @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options) {
            this.updateSelection(changes.options.currentValue, false);
            this.filteredMulti.next(this.options.slice());
        }

        if (changes.selection && changes.selection.firstChange == false) {
            this.setSelection(changes.selection.currentValue);
        }
    }

    /**
     * Set the current selection based on array of identifiers
     * (If empty array - all will be selected)
     * */
    setSelection(incomingSelection: number[]) {
        if (incomingSelection && this.options && incomingSelection.length === 0) {
            incomingSelection = this.options.map((x) => x.id);
        }

        this.selection = incomingSelection;

        let initialObjectSelection: MultiSelectIdValue[] = [];
        this.options.forEach((x) => {
            if (incomingSelection.includes(x.id)) {
                initialObjectSelection.push(x);
            }
        });

        this.updateSelection(initialObjectSelection, false);
    }

    ngOnInit() {
        if (!this.options) {
            return;
        }
        this.setSelection(this.selection);

        // load the initial bank list
        this.filteredMulti.next(this.options.slice());
        this.filterMulti();
        this.setToggleAllCheckboxState();

        // listen for search field value changes
        this.MultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterMulti();
            this.setToggleAllCheckboxState();
        });

        // listen for multi select field value changes
        this.MultiCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.setToggleAllCheckboxState();
        });
    }

    ngAfterViewInit() {
        this.setInitialValue();
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    openedChange(event) {
        if (event == true) {
            // - drop down opened
            this.MultiCtrl.setErrors(null, { emitEvent: false });
        } else {
            // - drop down closed
            const currentSelection: number[] = this.MultiCtrl.value.map((x) => x.id);
            if (this.options.length >= this.minimumSelection && this.minimumSelection > 1) {
                const filteredSelectionLength = currentSelection.filter(
                    (x) => !this.minimumSelectionExclusions.includes(x)
                ).length;
                if (filteredSelectionLength < this.minimumSelection) {
                    this.MultiCtrl.setErrors({ minLength: this.minimumSelection }, { emitEvent: false });
                }
            }

            if (this.MultiCtrl.valid) {
                this.selection = currentSelection;
                this.selectionChange.emit(this.selection);
            }
        }
    }

    getCurrentSelection(): MultiSelectIdValue[] {
        let selection: MultiSelectIdValue[] = [];

        if (this.MultiCtrl) {
            this.MultiCtrl.value.forEach((element) => {
                if (element != null) selection.push(element);
            });
        }

        return selection;
    }

    /** Select All Toggle */
    toggleSelectAll(selectAllValue) {
        this.filteredMulti.pipe(take(1), takeUntil(this._onDestroy)).subscribe((val) => {
            if (selectAllValue) {
                this.MultiCtrl.patchValue(val);
            } else {
                this.MultiCtrl.patchValue([]);
            }
        });
    }

    updateSelection(options: MultiSelectIdValue[], emitChangeEvent: boolean) {
        this.MultiCtrl.setValue(options);

        if (emitChangeEvent) {
            var multiFilter = this.MultiCtrl.value;
            // get selected filter value
            let selectedValue = multiFilter.filter((item) => item.id == options[0].id);

            var filterArrLength = this.MultiCtrl.value.length;

            for (let index = 0; index < filterArrLength; index++) {
                this.MultiCtrl.value.splice(0, 1);
            }

            // set selected filter value in multi filter
            this.MultiCtrl.setValue(selectedValue);
            this.selectionChange.emit(this.MultiCtrl.value((x) => x.Id));
        }
    }

    /** Select Only This Button Click */
    selectOnlyThis(event: MouseEvent, option: MultiSelectIdValue) {
        event.stopPropagation();
        this.updateSelection([option], true);
    }

    /** Sets the initial value of hte multi-select */
    protected setInitialValue() {
        this.filteredMulti.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the options are loaded initially
            // and after the mat-option elements are available
            this.multiSelect.compareWith = (a: MultiSelectIdValue, b: MultiSelectIdValue) => a && b && a.id === b.id;
        });
    }

    protected filterMulti() {
        if (!this.options) {
            return;
        }
        // get the search keyword
        let search = this.MultiFilterCtrl.value;
        if (!search) {
            this.filteredCache = this.options.slice();
            this.filteredMulti.next(this.filteredCache);
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the options
        this.filteredCache = this.options.filter((option) => option.name.toLowerCase().indexOf(search) > -1);
        this.filteredMulti.next(this.filteredCache);
    }

    protected setToggleAllCheckboxState() {
        let filteredLength = 0;
        if (this.MultiCtrl && this.MultiCtrl.value) {
            this.filteredCache.forEach((el) => {
                if (this.MultiCtrl.value.indexOf(el) > -1) {
                    filteredLength++;
                }
            });
            this.isIndeterminate = filteredLength > 0 && filteredLength < this.filteredCache.length;
            this.isChecked = filteredLength > 0 && filteredLength === this.filteredCache.length;
        }
    }
}
