import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
    DashboardsServiceV2,
    DimensionColumnTypeInd,
    DimensionDefinitionDTO,
    WidgetDataDTO,
    WidgetDataSetRequestDTO,
    WidgetDefinitionDTO,
    WidgetFilterDto,
} from 'src/app/core/services/dashboardsv2.service';

@Component({
    selector: 'app-widget-table',
    templateUrl: './widget-table.component.html',
    styleUrls: ['./widget-table.component.scss'],
    providers: [DashboardsServiceV2],
})
export class TableWidgetComponent implements OnInit, OnChanges {
    @Input() widgetConfig: WidgetDefinitionDTO;
    @Input() dataSetRequest: WidgetDataSetRequestDTO;
    @Input() loadWidgetData: boolean;

    @Output() mapDialogButtonClicked = new EventEmitter<string>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('filterInput') filterInput: HTMLInputElement;

    private _sort: MatSort;
    @ViewChild(MatSort) set sort(value: MatSort) {
        this._sort = value;
        if (this.dataSource) {
            this.dataSource.sort = this._sort;
        }
    }

    dataSource: MatTableDataSource<any[]>;

    columns: {
        // unique string for each column
        columnDef: string;
        // header text
        header: string;
        // used when header groups are in place for ungrouped headers
        headerRowSpan?: number;
        displayHeader: boolean;
        // class to apply on the column cells
        class: string;
        // displays a percentage-width bar in the table cell
        isBar: boolean;
        // url for map pop-up buttons
        headerUrl: string;
        index: number;
        sticky: boolean;
        cellUrl: (row: any[]) => string;
        // function to get the display value of the column cells
        cell: (row: any[]) => string;
        cellColour: (row: any[]) => string;
    }[];

    headerGroups: {
        columnDef: string;
        name: string;
        colSpan: number;
    }[] = [];
    displayedHeaderGroups: string[] = [];

    displayedColumns: string[] = [];
    subscription: any;
    isLoaded: boolean;
    hasFilters: boolean;
    sortActive: string;
    sortDirection: SortDirection;
    tableFilter = '';

    constructor(private dashboardService: DashboardsServiceV2) {}

    ngOnInit(): void {
        if (this.widgetConfig.widgetFilters && this.widgetConfig.widgetFilters.length > 0) {
            this.hasFilters = true;
        }

        if (this.loadWidgetData) {
            this.loadData();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dataSetRequest && !changes.dataSetRequest.isFirstChange()) {
            if (this.loadWidgetData) {
                this.loadData();
            } else {
                this.isLoaded = false;
            }
        }

        if (changes.loadWidgetData && !this.isLoaded && !changes.loadWidgetData.isFirstChange()) {
            if (changes.loadWidgetData.currentValue) {
                this.loadData();
            }
            //stop loading unfinished widget
            if (!changes.loadWidgetData.currentValue && this.subscription) {
                this.subscription.unsubscribe();
            }
        }
    }

    loadData() {
        this.widgetConfig.loading = true;
        this.isLoaded = false;
        let request: WidgetDataSetRequestDTO = JSON.parse(JSON.stringify(this.dataSetRequest));

        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        if (this.hasFilters) {
            this.widgetConfig.widgetFilters.forEach((widgetFilter: WidgetFilterDto) => {
                request.filters.push({
                    filterTypeInd: 0,
                    filterId: widgetFilter.filterId,
                    filterValues: [widgetFilter.filterSelection],
                });
            });
        }

        this.subscription = this.dashboardService
            .getDataForWidget(this.widgetConfig.dataEndpoint, request)
            .subscribe((response: WidgetDataDTO) => {
                this.columns = [];
                this.headerGroups = [];
                this.displayedColumns = [];
                this.displayedHeaderGroups = [];

                response.dimensions
                    .filter((dimension: DimensionDefinitionDTO) => !dimension.hidden)
                    .map((item, index, array) => {
                        const columnDef = index + '-' + item.name.replace(new RegExp(/\s/g), '');
                        if (!item.headerColSpan) {
                            this.columns.push({
                                columnDef: columnDef,
                                header: item.name,
                                headerRowSpan: null,
                                displayHeader: item.headerRowSpan == null,
                                class:
                                    this.widgetConfig.tableGraph && index == array.length - 1 || item.columnType == DimensionColumnTypeInd.HorizontalBar
                                        ? 'bar'
                                        : this.isNumber(response.data[0]?.[index])
                                        ? 'number'
                                        : 'text',
                                isBar: this.widgetConfig.tableGraph && index == array.length - 1 || item.columnType == DimensionColumnTypeInd.HorizontalBar,
                                headerUrl: item.url,
                                index: index,
                                sticky: index == 0 && !this.isNumber(response.data[0]?.[index]),
                                cellUrl: (row: any) => row[index].url ?? null,
                                cell: (row: any) => this.numberWithCommas(row[index].displayValue ?? row[index]),
                                cellColour: (row: any) =>
                                    item.colours.length > 0
                                        ? this.applyHeatMap(row[index], index, item.colours[0], item.colours[1])
                                        : 'inherit',
                            });
                            this.displayedColumns.push(columnDef);
                        }

                        if (item.headerRowSpan) {
                            if (this.columns[this.columns.length - 1]) {
                                this.columns[this.columns.length - 1].class += ' border-right';
                            }
                            this.columns.push({
                                columnDef: columnDef + '-2',
                                header: item.name,
                                headerRowSpan: item.headerRowSpan,
                                displayHeader: true,
                                class:
                                    (this.widgetConfig.tableGraph && index == array.length - 1
                                        ? 'bar'
                                        : this.isNumber(response.data[0]?.[index])
                                        ? 'number'
                                        : 'text') + ' border-right',
                                isBar: this.widgetConfig.tableGraph && index == array.length - 1 || item.columnType == DimensionColumnTypeInd.HorizontalBar,
                                headerUrl: item.url,
                                index: index,
                                sticky: index == 0 && !this.isNumber(response.data[0]?.[index]),
                                cellUrl: (row: any) => row[index].url ?? null,
                                cell: (row: any) => this.numberWithCommas(row[index].displayValue ?? row[index]),
                                cellColour: (row: any) =>
                                    item.colours.length > 0
                                        ? this.applyHeatMap(row[index], index, item.colours[0], item.colours[1])
                                        : 'inherit',
                            });
                            this.displayedHeaderGroups.push(columnDef + '-2');
                        }

                        if (item.headerColSpan) {
                            this.headerGroups.push({
                                columnDef: columnDef,
                                name: item.name,
                                colSpan: item.headerColSpan,
                            });
                            if (this.columns[this.columns.length - 1]) {
                                this.columns[this.columns.length - 1].class += ' border-right';
                            }
                            this.displayedHeaderGroups.push(columnDef);
                        }
                    });

                this.widgetConfig.loading = false;
                this.isLoaded = true;
                if (response.data) {
                    //remove hidden dimensions from data
                    response.dimensions.forEach((dimension: DimensionDefinitionDTO, index: number) => {
                        if (dimension.hidden) {
                            response.data.forEach((row: any[]) => {
                                row.splice(index, 1);
                            });
                        }
                    });

                    this.dataSource = new MatTableDataSource<any[]>(response.data);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => this.getSortData(data, sortHeaderId);
                    this.dataSource.filterPredicate = (data, filter) => {
                        return data.some((value) => {
                            const filterValue: string = this.numberWithCommas(
                                value.displayValue ?? value
                            ).toLowerCase();
                            return filterValue.includes(filter);
                        });
                    };

                    if (this.widgetConfig.sortFieldIndex != null) {
                        this.sortActive = this.columns.find(
                            (col) => col.index == this.widgetConfig.sortFieldIndex && col.displayHeader
                        )?.columnDef;
                        this.sortDirection = this.widgetConfig.sortFieldAscending ? 'asc' : 'desc';
                        if (this._sort) {
                            this._sort.sort({
                                id: this.sortActive,
                                start: this.sortDirection,
                                disableClear: false,
                            });
                        }
                    }

                    if (this.widgetConfig.tableFilter) {
                        this.tableFilter = this.widgetConfig.tableFilter;
                        this.applyFilter(this.tableFilter);
                    } else {
                        this.tableFilter = '';
                        this.applyFilter('');
                    }
                }
            });
    }

    updateWidgetFilter(filterId: string, filterKey: number) {
        const widgetFilter = this.widgetConfig.widgetFilters.find((filter) => filter.filterId === filterId);
        if (widgetFilter.filterSelection != filterKey) {
            widgetFilter.filterSelection = filterKey;
            this.loadData();
        }
    }

    private getSortData(data: any[], sortHeaderId: string): string | number {
        const index = this.columns.find((x) => x.columnDef === sortHeaderId).index;
        const value: string = (data[index].displayValue ?? data[index]).toString();
        //check for and convert value to number
        if (this.isNumber(value)) {
            const numberValue = this.convertStringToNumber(value);
            if (numberValue) {
                return numberValue;
            }
        }

        return data[index].displayValue ?? data[index];
    }

    private isNumber(value: string): boolean {
        //takes into account negative symbols, currency ($ or £), decimal places and percentage symbols
        const numberRegex = new RegExp(/^(?:- ?)?(?:[$£] ?)?(?:\d+|\d{1,3}(?:\,\d{3})+)(?:\.\d+)?(?: ?%)?$/);
        return numberRegex.test(value);
    }

    private convertStringToNumber(value: string): number {
        const strippedValue = value.replace(/[^\d\.-]/g, ''); //strip all but numbers, decimal points, and negative symbols
        if (!isNaN(+strippedValue)) {
            return +strippedValue;
        }

        return null;
    }

    handleFilterKeyUp(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.applyFilter(filterValue);
    }

    applyFilter(filter: string): void {
        this.dataSource.filter = filter.trim().toLowerCase();
        this.widgetConfig.tableFilter = this.dataSource.filter;

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    numberWithCommas(item: any) {
        if (typeof item === 'number') {
            return item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else if (typeof item === 'string') {
            return item;
        }
    }

    openMapDialog(event: MouseEvent, url: string) {
        event.stopPropagation();
        this.mapDialogButtonClicked.emit(url);
    }

    applyHeatMap(value: any, dataIndex: number, lowerColour: string, upperColour: string): string {
        if (this.isNumber(value)) {
            const numberValue = this.convertStringToNumber(value);
            if (numberValue && numberValue !== 0) {
                const dataSet = this.dataSource.data
                    .filter((row) => this.isNumber(row[dataIndex]))
                    .map((row) => this.convertStringToNumber(row[dataIndex]))
                    .filter((value) => value !== 0);

                const threshold = dataSet.reduce((a, b) => (numberValue < 0 ? Math.min(a, b) : Math.max(a, b)));
                const percentage = numberValue / threshold;

                const alphaHex = Math.round(percentage * 255)
                    .toString(16)
                    .padStart(2, '0');

                return (numberValue < 0 ? lowerColour : upperColour) + alphaHex;
            }
        }
        return 'inherit';
    }

    updateSortSelection(event: Sort): void {
        const index = this.columns.find((x) => x.columnDef === event.active)?.index;
        if (index != null) {
            this.widgetConfig.sortFieldIndex = index;
            this.widgetConfig.sortFieldAscending = event.direction === 'asc';
        }
    }
}
