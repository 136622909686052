import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { from } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import BaseComponent from 'src/app/core/base/base-component.component';
import { MapService } from 'src/app/core/services/maps/map.service';
import { MapComponent } from 'src/app/modules/maps/map/map.component';
import { MapDataDto } from 'src/app/shared/models/maps';
import { WidgetMapFilterDto } from 'src/app/shared/models/maps/widget-map-filter-dto.model';
import { BoundingBoxDTO } from 'src/app/shared/models/movement/boundingBoxDto';

@Component({
    selector: 'app-map-modal',
    templateUrl: './map-modal.component.html',
    styleUrls: ['./map-modal.component.scss'],
})
export class MapModalComponent extends BaseComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { mapData: MapDataDto; title: string },
        private mapService: MapService
    ) {
        super();
    }

    mapLoaded = false;

    mapComponent: typeof MapComponent;
    mapInput: {
        boundingBox: BoundingBoxDTO;
    };

    ngOnInit(): void {
        this.mapInput = {
            boundingBox: this.data.mapData.boundingBox,
        };
        //lazy load the map component
        from(import('src/app/modules/maps/map/map.component'))
            .pipe(
                take(1),
                tap(({ MapComponent }) => {
                    this.mapComponent = MapComponent;
                }),
                //then wait for the map to load
                switchMap(() => this.mapService.mapLoaded$.pipe(filter((x) => x))),
                takeUntil(this.subscriptionComplete)
            )
            .subscribe(() => {
                //once map has loaded, add the source/layer data
                this.mapLoaded = true;
                this.mapService.addMapData(this.data.mapData);
            });
    }

    updateFilter(filter: WidgetMapFilterDto, event: MatButtonToggleChange) {
        // show/hide layers based on filter
        this.data.mapData.layers
            .filter((layer) => layer.filterId == filter.filterId)
            .forEach((layer) => {
                this.mapService.addOrUpdateCurrentFilter({
                    id: 0,
                    layerId: layer.id,
                    layerSourceName: layer.source,
                    visibility: layer.filterValue == event.value,
                    color: null,
                });
            });
    }
}
