import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../core/services/auth.service';
import { MessageService } from '../../../core/services/message.service';
import { UserService } from '../../../core/services/user.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';

@Component({
	selector: 'app-password-change',
	templateUrl: './password-change.component.html',
	styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnInit {
	@Output() showErrors = new EventEmitter<string[]>();

	form: UntypedFormGroup;
	loading: boolean = false;
	matchedPolicy: boolean = true;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private userService: UserService,
		private authenticationService: AuthService,
		private messageService: MessageService,
		private errHandlerService: ErrorHandlerService
	) {}

	// convenience getter for easy access to form fields
	get f() {
		return this.form.controls;
	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			oldPassword: ['', Validators.required],
			password: ['', [Validators.required, Validators.minLength(8)]],
			confirmpassword: ['', [Validators.required, Validators.minLength(8)]],
		});
	}

	onStrengthChanged(strength: number) {
		window.setTimeout(() => {
			if (strength < 80) {
				this.matchedPolicy = false;
			} else {
				this.matchedPolicy = true;
			}
		});
	}

	/** Server Errors */
	handleError(err: any) {
		this.loading = false;
		this.showErrors.emit(this.errHandlerService.handleError(err));
	}

	/** Save Button */
	onSubmit(pwdStrength: any) {
		this.showErrors.emit([]);

		// -- check still logged in
		let isLoggedIn = this.authenticationService.isUserLoggedIn();
		if (!isLoggedIn) {
			this.messageService.showError('Your session has expired - please re-login to change your password');
			return;
		}

		// -- check password stength
		if (pwdStrength < 80) {
			this.matchedPolicy = false;
			this.messageService.showError('New password does not meet required password strength');
			return;
		}

		// -- check new passwords match
		if (this.f.password.value != this.f.confirmpassword.value) {
			this.f.confirmpassword.setErrors(Validators.required);
			this.messageService.showError('The new password and confirmed new password do not match');
			return;
		}

		// -- change password
		if (this.form.valid) {
			this.changePassword();
		}
	}

	/** Change Password */
	changePassword() {
		this.loading = true;

		let userName: string = this.authenticationService.getUserName();

		this.userService.changepwd(userName, this.f.oldPassword.value, this.f.password.value).subscribe(
			(data) => {
				this.loading = false;
				if (data) {
					this.messageService.showSuccess('Your password has been changed.');
				}
			},
			(err) => this.handleError(err)
		);
	}
}
