<mat-form-field appearance="outline" class="w-100">
	<mat-label>{{ label }}</mat-label>
	<mat-select
		required="true,"
		[formControl]="MultiCtrl"
		[placeholder]="placeholder"
		[multiple]="allowMultiple"
		panelWidth=""
		panelClass="filter-multiselect-panel"
		(openedChange)="openedChange($event)"
		#multiSelect>
		<mat-select-trigger>
			<span *ngIf="MultiCtrl?.value?.length === options?.length">{{ placeholder }}</span>
			<span *ngIf="MultiCtrl?.value?.length === 1 && options?.length > 1">Only {{ MultiCtrl.value[0].name }}</span>
			<span *ngIf="MultiCtrl?.value?.length > 1 && MultiCtrl?.value?.length < options.length">
				{{ MultiCtrl.value[0].name }}
				(+{{ MultiCtrl.value.length - 1 }} {{ MultiCtrl.value?.length === 2 ? 'other' : 'others' }})
			</span>
		</mat-select-trigger>

		<mat-option>
			<ngx-mat-select-search
				[showToggleAllCheckbox]="true"
				[toggleAllCheckboxIndeterminate]="isIndeterminate"
				[toggleAllCheckboxChecked]="isChecked"
				(toggleAll)="toggleSelectAll($event)"
				[formControl]="MultiFilterCtrl"
				placeholderLabel="Search ..."
				noEntriesFoundLabel="no match found">
			</ngx-mat-select-search>
		</mat-option>
		<mat-accordion displayMode="flat" multi #accordion="matAccordion">
			<mat-expansion-panel
				class="remove-expansion-panel-body-padding mat-elevation-z0"
				*ngFor="let group of filteredOptionGroups | async"
				hideToggle
				#panel>
				<mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px">
					<mat-checkbox
						color="primary"
						[checked]="getGroupState(group).isChecked"
						[indeterminate]="getGroupState(group).isIndeterminate"
						(change)="toggleSelectAllGroup($event.checked, group)"
						(click)="$event.stopPropagation()">
						{{ group ?? 'Ungrouped' }}
					</mat-checkbox>
					<mat-icon *ngIf="!panel.expanded">expand_more</mat-icon>
					<mat-icon *ngIf="panel.expanded">expand_less</mat-icon>
				</mat-expansion-panel-header>
				<ng-container *ngFor="let option of filteredMulti | async">
					<div matRipple class="filter-wrapper" *ngIf="option.group === group" (click)="selectOption(option)">
						<div class="filter-wrapper__inner">
							<mat-option class="filter-option" [value]="option">
								{{ option.name }}
							</mat-option>
						</div>
						<button type="button" (click)="selectOnlyThis($event, option)">Only This</button>
					</div>
				</ng-container>
			</mat-expansion-panel>
		</mat-accordion>
	</mat-select>
</mat-form-field>
