import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MultiSelectIdValue } from '../filter-multiselect/filter-multiselect.component';

export interface GeographicOption {
    levelId: number;
    levelName: string;
    locations: MultiSelectIdValue[];
    allPlaceholder: string;
}

export interface GeographicSelection {
    level: number;
    dimensionId: number[];
}

@Component({
    selector: 'app-geographic-filter',
    templateUrl: './filter-geographic.component.html',
    styleUrls: ['./filter-geographic.component.scss'],
})
export class GeographicFilterComponent implements OnInit, OnChanges {
    @Input() options: GeographicOption[] = [];
    @Input() topLevelId: number = 6;
    lowestFilterLevel: number = 1;

    @Input() selectionUpdate: GeographicSelection;
    @Input() selection!: number[];
    @Output() selectionChange = new EventEmitter<number[]>();

    levelSelection: { [levelId: number]: number[] } = {};
    completeGeography: { [levelId: number]: MultiSelectIdValue[] } = [];
    filteredGeography: { [levelId: number]: MultiSelectIdValue[] } = [];
    MultiOptions: GeographicOption[] = [];

    ngOnInit(): void {
        this.setSelection(false);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectionUpdate?.currentValue) {
            setTimeout(() => {
                let newSelection: number[] = [];
                newSelection.push(changes.selectionUpdate.currentValue[0].dimensionId);
                this.setSelectionForLevel(changes.selectionUpdate.currentValue[0].level, newSelection, true);
            }, 0);
        }

        if (changes.selection && !changes.selection.firstChange) {
            this.options = JSON.parse(JSON.stringify(this.MultiOptions));
            this.setSelection(true);
        }
    }

    /**
     * Selected value of a drop down has changed
     * @param levelId
     * @param selection
     */
    selectionChanged(levelId: number, selection: any) {
        this.setSelectionForLevel(levelId, selection, true);
    }

    setSelection(updateWidgets: boolean) {
        let visibleOptions = this.options.length;
        if (this.options) {
            // step over geographic levels from lowest to highest
            const lastIndex = this.options.length - 1;
            for (var i = lastIndex; i >= 0; i--) {
                const option = this.options[i];
                // set complete and filtered values for level to all geographies
                this.completeGeography[option.levelId] = option.locations;
                this.filteredGeography[option.levelId] = this.completeGeography[option.levelId].map((x) => x);

                // 
                const previousOption = this.options[i + 1];
                if (previousOption) {
                    // get a list of the parentIds from the previous (next lower) level selection
                    const previousLevelParentIds = this.filteredGeography[previousOption.levelId]
                        .filter((x) => this.levelSelection[previousOption.levelId].includes(x.id))
                        .flatMap((x) => x.parentIds);
                    // filter the current level selection by the previous level's parentIds
                    this.levelSelection[option.levelId] = this.filteredGeography[option.levelId]
                        .filter((x) => previousLevelParentIds.includes(x.id))
                        .map((x) => x.id);

                    // update the previous level's filtered values to reflect the selected values of the current level
                    this.filteredGeography[previousOption.levelId] = this.filteredGeography[previousOption.levelId]
                        .filter((x) => x.parentIds.some((y) => this.levelSelection[option.levelId].includes(y)));

                    if (this.filteredGeography[previousOption.levelId].length == this.levelSelection[previousOption.levelId].length) {
                        visibleOptions--;
                    }
                } else {
                    // set the lowest level's selection to the inputted values
                    this.levelSelection[option.levelId] = this.selection;
                }
            }
            this.lowestFilterLevel = this.options[lastIndex].levelId;
        }

        this.MultiOptions = JSON.parse(JSON.stringify(this.options));

        this.options.splice(visibleOptions + 1, this.options.length - 1);

        if (updateWidgets) {
            this.selectionChange.emit(this.levelSelection[this.lowestFilterLevel]);
        }
    }

    setSelectionForLevel(levelId: number, newSelection: number[], updateWidgets: boolean) {
        this.options = JSON.parse(JSON.stringify(this.MultiOptions));

        this.levelSelection[levelId] = newSelection;

        if (newSelection.length == 0) {
            this.filteredGeography[levelId] = this.completeGeography[levelId].map((x) => x);
            this.levelSelection[levelId] = this.filteredGeography[levelId].map((x) => x.id);
        } else {
            for (let i = levelId - 1; i >= this.lowestFilterLevel; i--) {
                this.filteredGeography[i] = [];
                this.filteredGeography[i] = this.completeGeography[i].filter((x) =>
                    x.parentIds.some((r) => this.levelSelection[i + 1].indexOf(r) > -1)
                );

                this.levelSelection[i] = this.filteredGeography[i].map((x) => x.id);
            }
        }

        if (updateWidgets) {
            for (let i = levelId; i > this.lowestFilterLevel; i--) {
                this.options.splice(this.options.length - 1, levelId - i);
            }

            this.selectionChange.emit(this.levelSelection[this.lowestFilterLevel]);
        }
    }
}
