<!-- <pre>{{selectedOrganisation | json}}</pre> -->

<section class="filters sticky-top">
	<div class="filter__container" *ngIf="config?.organisationDataSets.length > 1">
		<mat-form-field appearance="outline">
			<mat-label>Organisation</mat-label>
			<mat-select [(value)]="selectedOrganisation" (selectionChange)="createOrganisationChangeRequest()">
				<mat-option *ngFor="let org of config?.organisationDataSets" [value]="org">
					{{ org.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="filter__container" *ngIf="selectedOrganisation?.dataSets.length > 1">
		<mat-form-field appearance="outline" class="w-100">
			<mat-label>Data Set</mat-label>
			<mat-select [(value)]="selectedOrganisationDataSet" (selectionChange)="createOrganisationDataSetChangeRequest()">
				<mat-option *ngFor="let dataset of selectedOrganisation?.dataSets" [value]="dataset">
					{{ dataset.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<ng-container *ngFor="let filter of config?.filters">
		<div class="filter__container" *ngIf="filter.filterFormInd === DashboardFilterFormInd.DropDown && filter.allowMultiSelect && !filter.grouped">
			<app-multi-select-filter
				[options]="filter.options"
				[label]="filter.label"
				[placeholder]="filter.allPlaceholder"
				[allowMultiple]="filter.allowMultiSelect"
				[selection]="filter.selection"
				[minimumSelection]="filter.minimumSelection"
				[minimumSelectionExclusions]="filter.minimumSelectionExclusions"
				(selectionChange)="onFilterChanged(filter, $event)">
			</app-multi-select-filter>
		</div>

		<div class="filter__container" *ngIf="filter.filterFormInd === DashboardFilterFormInd.DropDown && filter.allowMultiSelect && filter.grouped">
			<app-grouped-multi-select-filter
				[options]="filter.options"
				[label]="filter.label"
				[placeholder]="filter.allPlaceholder"
				[allowMultiple]="filter.allowMultiSelect"
				[selection]="filter.selection"
				(selectionChange)="onFilterChanged(filter, $event)">
			</app-grouped-multi-select-filter>
		</div>

		<div class="filter__container" *ngIf="filter.filterFormInd === DashboardFilterFormInd.DropDown && !filter.allowMultiSelect">
			<app-singleselect-container
				[placeholder]="filter.label"
				[singleOptions]="filter.options"
				[singleFilter]="filter"
                [selection]="filter.selection[0]"
				(filtersChanged)="onSingleSelection(filter, $event)">
			</app-singleselect-container>
		</div>

		<app-geographic-filter
			*ngIf="filter.filterFormInd === DashboardFilterFormInd.Geographic"
			[topLevelId]="filter.topLevelId"
			[options]="filter.options"
			[selection]="filter.selection"
			[selectionUpdate]="filter.selectionUpdate"
			(selectionChange)="onFilterChanged(filter, $event)">
		</app-geographic-filter>

		<div class="filter__container" *ngIf="filter.filterFormInd === DashboardFilterFormInd.Slider">
			<app-filter-slider
				[label]="filter.label"
				[min]="filter.min"
				[max]="filter.max"
				[step]="filter.step"
				[selection]="filter.selection"
				[labelValue]="filter.labelValue"
				(selectionChange)="onFilterChanged(filter, $event)">
			</app-filter-slider>
		</div>

	</ng-container>
</section>
