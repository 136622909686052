<ng-container *ngIf="isPrimaryOnly; then primaryOnly; else supplimentary"></ng-container>

<ng-template #primaryOnly>
	<div class="statsContainer__primaryOnly">
		<div class="item" *ngFor="let data of widgetData?.data">
			<div>
				<span class="label" [innerHtml]="formatString(data[0])"></span>
				<span class="value">{{ data[1] }}</span>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #supplimentary>
	<div class="statsContainer__primary">
		<div *ngIf="widgetData?.data.length > 0">
			<span class="label">{{ widgetData?.data[0][0] }}</span>
			<span class="value">{{ widgetData?.data[0][1] }}</span>
		</div>
	</div>
	<div class="statsContainer__supplimentary">
		<div class="item" *ngFor="let data of widgetData?.data.slice(1)">
			<div>
				<span class="label" [innerHtml]="formatString(data[0])"></span>
				<span class="value">{{ data[1] }}</span>
			</div>
		</div>
	</div>
</ng-template>
