<section class="widget-container" [class.widget-container--drilldown]="hasFilters">
  <!-- eCharts Component -->
  <div echarts [options]="echartOptions"></div>
  <div *ngIf="hasFilters" class="drilldown-panel">
    <button
      type="button"
      *ngFor="let filter of widgetConfig.widgetFilters[0].filterOptions | keyvalue"
      (click)="updateWidgetFilter(widgetConfig.widgetFilters[0].filterId, +filter.key)"
      [class.active]="widgetConfig.widgetFilters[0].filterSelection === +filter.key">
      <span #sidePanelButtonText>{{ filter.value }}</span>
    </button>
  </div>
</section>
