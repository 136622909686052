import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import {
	DashboardsServiceV2,
	WidgetDataDTO,
	WidgetDataSetRequestDTO,
	WidgetDefinitionDTO,
} from 'src/app/core/services/dashboardsv2.service';

@Component({
	selector: 'app-stacked-graph',
	templateUrl: './widget-stacked.component.html',
})
export class WidgetStackedComponent implements OnChanges {
	@Input() widgetConfig: WidgetDefinitionDTO;
	@Input() dataSetRequest: WidgetDataSetRequestDTO;
	@Input() loadWidgetData: boolean;

	echartOptions: EChartsOption;
	dashboardService: DashboardsServiceV2;
	widgetData: WidgetDataDTO;
	subscription: any;
	isLoaded: boolean;

	constructor(dashboardService: DashboardsServiceV2) {
		this.dashboardService = dashboardService;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.dataSetRequest && !changes.dataSetRequest.isFirstChange()) {
			if (this.loadWidgetData) {
				this.loadData();
			} else {
				this.isLoaded = false;
			}
		}

		if (changes.loadWidgetData && !this.isLoaded) {
			if (changes.loadWidgetData.currentValue) {
				this.loadData();
			}
			//stop loading unfinished widget
			if (!changes.loadWidgetData.currentValue && this.subscription) {
				this.subscription.unsubscribe();
			}
		}
	}

	loadData() {
		this.widgetConfig.loading = true;
		this.isLoaded = false;

		if (this.subscription) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.dashboardService
			.getDataForWidget(this.widgetConfig.dataEndpoint, this.dataSetRequest)
			.subscribe((response: WidgetDataDTO) => {
				this.widgetData = response;
				this.widgetConfig.loading = false;
				this.isLoaded = true;
				if (response.data) {
					this.getStackedData();
				}
			});
	}

	/** Create data for Stacked area graph */
	getStackedData() {
		// get list of xAxis data (months)
		let xAxisLabel = this.widgetData.dimensions.map((a) => a.name);

		let axisOptions = [];
		let dataArray = this.widgetData.data;

		//get data in format
		dataArray.forEach((element, index) => {
			let currLen = dataArray[index].length;
			axisOptions.push({
				type: 'line',
				stack: 'Total',
				emphasis: { focus: 'series' },
				areaStyle: {},
				name: element[0],
				data: element.splice(1, currLen),
			});
		});

		//get the name to display as legends
		let legendData = dataArray.map(function (item) {
			return item[0];
		});

		// set eChart properties
		this.echartOptions = {
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
					label: {
						backgroundColor: '#6a7985',
					},
				},
			},
			toolbox: {
				show: true,
				feature: {
					magicType: { show: true, type: ['line', 'bar'] },
					dataZoom: { show: false },
					mark: { show: true },
					restore: { show: true },
					saveAsImage: {
						show: true,
						name: ('SportsEye_' + this.widgetConfig.title.replace(' ', '_') + '_Export').toLowerCase(),
					},
				},
			},
			legend: {
				top: 10,
				data: legendData,
			},
			grid: {
				left: '8%',
				right: '8%',
				bottom: '3%',
				containLabel: true,
			},
			xAxis: {
				type: 'category',
				boundaryGap: false,
				data: xAxisLabel,
			},
			yAxis: {
				type: 'value',
				name: this.widgetConfig.title,
				nameLocation: 'middle',
				nameGap: 55,
			},
			series: axisOptions,
		};
	}
}
