import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SliderValue } from 'src/app/shared/models/slider-value.type';

@Component({
	selector: 'app-filter-slider',
	templateUrl: './filter-slider.component.html',
	styleUrls: ['./filter-slider.component.scss'],
})
export class FilterSliderComponent implements OnInit {
	@Input() selection: number[];
	@Input() label: string;
	@Input() min: number;
	@Input() max: number;
	@Input() step: number;
	@Input() labelValue: boolean = true;

	@Output() selectionChange = new EventEmitter<number[]>();

	formControl = new FormControl<SliderValue>(null);

	startValue: number;
	endValue: number;
	labelWithValue: string;

	ngOnInit(): void {
		let value: SliderValue =
			this.selection.length > 1 ? { start: this.selection[0], end: this.selection[1] } : this.selection[0];
		this.updateLabel(value);
		this.formControl.setValue(value);

		this.formControl.valueChanges.subscribe((value: SliderValue) => {
			this.updateLabel(value);
			if (typeof value === 'number') {
				this.selectionChange.emit([value]);
			} else {
				this.selectionChange.emit([value.start, value.end]);
			}
		});
	}

	updateLabel(value: SliderValue): void {
		if (typeof value === 'number') {
			this.labelWithValue = `${this.label} (${value})`;
		} else {
			this.labelWithValue = `${this.label} (${value.start} - ${value.end})`;
		}
	}
}
