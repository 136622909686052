import { NgModule } from '@angular/core';

import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardsComponent } from './dashboards/dashboards.component';
import { DashboardsV2RoutingModule } from './dashboards-routing.module';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from 'src/app/shared/shared.module';
import { MultiSelectFilterComponent } from './dashboard/filters/filter-multiselect/filter-multiselect.component';
import { FilterContainerComponent } from './dashboard/filters/filter-container.component';
import { GeographicFilterComponent } from './dashboard/filters/filter-geographic/filter-geographic.component';
import { GraphWidgetComponent } from './dashboard/widgets/widget-graph/widget-graph.component';
import { StatsWidgetComponent } from './dashboard/widgets/widget-stats/widget-stats.component';
import { TableWidgetComponent } from './dashboard/widgets/widget-table/widget-table.component';
import { SingleSelectComponent } from './dashboard/filters/filter-singleselect/filter-singleselect.component';
import { WidgetHeatmapComponent } from './dashboard/widgets/widget-heatmap/widget-heatmap.component';
import { WidgetScatterComponent } from './dashboard/widgets/widget-scatter/widget-scatter.component';
import { WidgetStackedComponent } from './dashboard/widgets/widget-stacked/widget-stacked.component';
import { FilterGroupedMultiselectComponent } from './dashboard/filters/filter-grouped-multiselect/filter-grouped-multiselect.component';
import { FilterSliderComponent } from './dashboard/filters/filter-slider/filter-slider.component';
import { WidgetStatsTableComponent } from './dashboard/widgets/widget-stats-table/widget-stats-table.component';
import { MapModalComponent } from './dashboard/modals/map-modal/map-modal.component';
import { ExportComponent } from './export/export.component';

// E-Charts
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxEchartsDirective } from 'ngx-echarts';

import * as echarts from 'echarts/core';
import { BarChart, PieChart } from 'echarts/charts';
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    TransformComponent,
    LegendComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { DashboardViewModalComponent } from './dashboard/modals/dashboard-view-modal/dashboard-view-modal.component';

echarts.use([
    TitleComponent,
    ToolboxComponent,
    LegendComponent,
    TooltipComponent,
    GridComponent,
    BarChart,
    PieChart,
    CanvasRenderer,
    TransformComponent,
]);

@NgModule({
    declarations: [
        DashboardsComponent,
        DashboardComponent,
        MultiSelectFilterComponent,
        GeographicFilterComponent,
        FilterContainerComponent,
        GraphWidgetComponent,
        StatsWidgetComponent,
        TableWidgetComponent,
        SingleSelectComponent,
        WidgetHeatmapComponent,
        WidgetScatterComponent,
        WidgetStackedComponent,
        FilterGroupedMultiselectComponent,
        FilterSliderComponent,
        WidgetStatsTableComponent,
        MapModalComponent,
        ExportComponent,
        DashboardViewModalComponent
    ],
    imports: [
        DashboardsV2RoutingModule,
        SharedModule,
        NgxMatSelectSearchModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
    ],
    providers: [{ provide: NgxEchartsDirective, useValue: {} }],
})
export class DashboardsV2Module {}
