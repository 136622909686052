import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { AuthService } from '../../../core/services/auth.service';
import { MessageService } from '../../../core/services/message.service';
import { environment } from 'src/environments/environment';
import BaseComponent from 'src/app/core/base/base-component.component';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent implements OnInit {
    returnUrl: string = '';
    registerUrl: string = '';
    termsOfUseUrl: string = '';
    hide = true;
    form = this.formBuilder.group({
        username: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
        rememberMe: [false],
    });

    get f() {
        return this.form.controls;
    }

    // Shameful hack that makes me feel bad
    // Rationale: themes are not applied to an environment, and are controlled by hostname
    // a new requirement requires 2 different sign up addresses based on theme, not domain.
    isCpra: boolean;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private messageService: MessageService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private errHandlerService: ErrorHandlerService
    ) {
        super();
    }

    ngOnInit(): void {
        this.authService.setIsLoggedIn('false');
        this.registerUrl = environment.registerLink;
        this.termsOfUseUrl = environment.termsOfUseLink;
        this.route.queryParams.pipe(takeUntil(this.subscriptionComplete)).subscribe((params) => {
            this.returnUrl = params.redirectURL ?? '';
        });

        this.isCpra = window.location.hostname.toLowerCase() === 'cpra.activexchange.ca';
    }

    async onSubmit() {
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.authService
            .login(this.f.username.value, this.f.password.value)
            .pipe(takeUntil(this.subscriptionComplete))
            .pipe(finalize(() => (this.loading = false)))
            .subscribe(
                (data) => {
                    this.loading = false;
                    if (data) {
                        this.authService.setSessionStorageFromAuthResponse(data, this.f.rememberMe.value);
                        this.messageService.isLoggedIn(true);
                        if (this.returnUrl.length > 1) this.router.navigateByUrl(this.returnUrl);
                        else this.router.navigate(['/']);
                    }
                },
                (err) => {
                    this.messageService.showError(this.errHandlerService.handleError(err)[0] ?? 'Login Failed');
                }
            );
    }
}
