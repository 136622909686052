import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import {
	DashboardsServiceV2,
	WidgetDataDTO,
	WidgetDataSetRequestDTO,
	WidgetDefinitionDTO,
	AxisTypeInd,
} from 'src/app/core/services/dashboardsv2.service';

@Component({
	selector: 'app-widget-scatter-graph',
	templateUrl: './widget-scatter.component.html',
	styleUrls: ['./widget-scatter.component.scss'],
})
export class WidgetScatterComponent implements OnChanges {
	@Input() widgetConfig: WidgetDefinitionDTO;
	@Input() dataSetRequest: WidgetDataSetRequestDTO;
	@Input() loadWidgetData: boolean;

	echartOptions: EChartsOption;
	dashboardService: DashboardsServiceV2;
	widgetData: WidgetDataDTO;
	isMagicTypeGraphFeature: boolean;
	isRestoreGraphFeature: boolean;
	subscription: any;
	isLoaded: boolean;

	constructor(dashboardService: DashboardsServiceV2) {
		this.dashboardService = dashboardService;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.dataSetRequest && !changes.dataSetRequest.isFirstChange()) {
			if (this.loadWidgetData) {
				this.loadData();
			} else {
				this.isLoaded = false;
			}
		}

		if (changes.loadWidgetData && !this.isLoaded) {
			if (changes.loadWidgetData.currentValue) {
				this.loadData();
			}
			//stop loading unfinished widget
			if (!changes.loadWidgetData.currentValue && this.subscription) {
				this.subscription.unsubscribe();
			}
		}
	}

	loadData() {
		this.isLoaded = false;
		this.isMagicTypeGraphFeature = this.widgetConfig.magicTypeGraphFeature;
		this.isRestoreGraphFeature = this.widgetConfig.restoreGraphFeature;

		if (this.subscription) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.dashboardService
			.getDataForWidget(this.widgetConfig.dataEndpoint, this.dataSetRequest)
			.subscribe((response: WidgetDataDTO) => {
				this.widgetData = response;
				this.isLoaded = true;
				if (response.data) {
					this.getScatterChartData();
				}
			});
	}

	getScatterChartData() {
		const data = this.widgetData.data;

		let xAxisLbl = '';
		let yAxisLbl = '';

		//Here check the Axis type and set the lable accordingly
		this.widgetData.axis.forEach((x) => {
			if (x.axisType === AxisTypeInd.XAxis) xAxisLbl = x.axisLabel;
			else if (x.axisType === AxisTypeInd.YAxis) yAxisLbl = x.axisLabel;
		});

		this.echartOptions = {
			toolbox: {
				show: true,
				feature: {
					magicType: {
						show: this.isMagicTypeGraphFeature,
						type: ['line', 'bar'],
					},
					dataZoom: {
						show: false,
					},
					mark: {
						show: false,
					},
					restore: {
						show: this.isRestoreGraphFeature,
					},
					saveAsImage: {
						show: true,
						name: ('SportsEye_' + this.widgetConfig.title.replace(' ', '_') + '_Export').toLowerCase(),
					},
				},
			},
			grid: {
				containLabel: true,
				left: 55,
				right: 55,
				bottom: 35,
			},
			tooltip: {
				position: 'top',
			},
			xAxis: {
				name: xAxisLbl,
				nameLocation: 'middle',
				nameGap: 30,
			},
			yAxis: {
				name: yAxisLbl,
				nameLocation: 'middle',
				nameGap: 55,
			},
			series: [
				{
					symbolSize: 20,
					data: data,
					type: 'scatter',
				},
			],
		};
	}
}
