<mat-card
	class="mat-elevation-z0 h-100"
	[ngClass]="{ accented: accent, 'hovered mat-elevation-z3': hovered }"
	(mouseover)="hovered = true"
	(mouseout)="hovered = false">
	<img mat-card-image src="{{ image }}" />
	<span class="new" *ngIf="isNew">New</span>
	<mat-card-content>
		<p class="fw-bold text-uppercase mb-2 mat-small date-text" *ngIf="date">{{ date | date : 'd MMMM y' }}</p>
		<mat-card-title class="lh-1">{{ title }}</mat-card-title>
		<p class="body-text">{{ body }}</p>
	</mat-card-content>
</mat-card>
