<mat-form-field appearance="outline" class="w-100">
	<mat-label>{{ label }}</mat-label>
	<mat-select
		required="true,"
		[formControl]="MultiCtrl"
		[placeholder]="placeholder"
		[multiple]="allowMultiple"
		panelClass="filter-multiselect-panel"
		panelWidth=""
		(openedChange)="openedChange($event)"
		#multiSelect>
		<mat-select-trigger>
			<span *ngIf="MultiCtrl?.value?.length == options?.length">{{ placeholder }}</span>
			<span *ngIf="MultiCtrl?.value?.length == 1 && options?.length > 1">Only {{ MultiCtrl.value[0].name }}</span>
			<span *ngIf="MultiCtrl?.value?.length > 1 && MultiCtrl?.value?.length < options.length">
				{{ MultiCtrl.value[0].name }}
				(+{{ MultiCtrl.value.length - 1 }} {{ MultiCtrl.value?.length === 2 ? 'other' : 'others' }})
			</span>
		</mat-select-trigger>

		<mat-option>
			<ngx-mat-select-search
				[showToggleAllCheckbox]="true"
				[toggleAllCheckboxIndeterminate]="isIndeterminate"
				[toggleAllCheckboxChecked]="isChecked"
				(toggleAll)="toggleSelectAll($event)"
				[formControl]="MultiFilterCtrl"
				placeholderLabel="Search ..."
				noEntriesFoundLabel="no match found">
			</ngx-mat-select-search>
		</mat-option>

		<mat-option class="filter-option" *ngFor="let option of filteredMulti | async" [value]="option">
			{{ option.name }}
			<span>
				<button type="button" (click)="selectOnlyThis($event, option)">Only This</button>
			</span>
		</mat-option>
	</mat-select>
	<mat-error *ngIf="!MultiCtrl.valid && MultiCtrl.errors?.['minLength']">{{
		'A minimum of ' + minimumSelection + ' options must be selected.'
	}}</mat-error>
</mat-form-field>
