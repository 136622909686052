<div class="container-fluid container-fluid--sePadding container-dashboardHomepage">
	<app-page-heading
		[iconName]="'insert_chart'"
		title="Dashboards"
		subTitle="Explore our business intelligence dashboards">
	</app-page-heading>

	<ng-container *ngIf="pageData">
		<section class="dashboardSection" *ngFor="let dashboardSection of pageData.dashboardSections">
			<div class="dashboardSection_sectionHeader">{{ dashboardSection.sectionName }}</div>

			<div class="dashboard-link-container">
				<a
					*ngFor="let dashboard of dashboardSection.dashboards"
					[routerLink]="'/dashboardsv2/' + dashboard.typeInd + '/' + dashboard.customDashboardId">
					<div class="image"><img [src]="'assets/images/dashboards/' + dashboard.typeInd + '.jpg'" /></div>
					<h3>{{ dashboard.name }}</h3>
					<p>{{ dashboard.description }}</p>
				</a>
			</div>
		</section>
	</ng-container>
</div>
