import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../../configs/config';
import { Observable } from 'rxjs';
import { MapDataDto } from 'src/app/shared/models/maps/map-data-dto.model';
import { DashboardViewDto } from 'src/app/shared/models/dashboards/dashboard-view-dto.model';

/**
 * Provides network services for the dashboards module
 * */
@Injectable()
export class DashboardsServiceV2 {
	private baseDashboardsControllerUrl: string = '/api/v1/DashboardsV2';
	private httpService: HttpClient;

	constructor(http: HttpClient) {
		this.httpService = http;
	}

	/** Get the list of available dashboards available to the currently logged in user. */
	getAvailableDashboardsForUser(): Observable<DashboardsDTO> {
		return this.httpService.get<DashboardsDTO>(Api.baseApiUrl + this.baseDashboardsControllerUrl);
	}

	/**
	 * Get the configuration for a specific dashboard
	 * @param typeInd The DashboardTypeInd (refer to DevOps Wiki)
	 */
	getDashboardConfigurationForUser(typeInd: number, customDashboardId: number) {
		return this.httpService.get(
			Api.baseApiUrl + this.baseDashboardsControllerUrl + '/' + typeInd + '/' + customDashboardId
		);
	}

	/**
	 * Get the data for a widget
	 */
	getDataForWidget(widgetDataUrl: string, request: WidgetDataSetRequestDTO) {
		return this.httpService.post(Api.baseApiUrl + widgetDataUrl, request);
	}

	getMapDataForWidget(mapDataUrl: string, request: WidgetDataSetRequestDTO): Observable<MapDataDto> {
		return this.httpService.post<MapDataDto>(Api.baseApiUrl + mapDataUrl, request);
	}

    getDashboardViews(dashboardTypeInd: number): Observable<DashboardViewDto[]> {
        return this.httpService.get<DashboardViewDto[]>(Api.baseApiUrl + '/api/v1/DashboardViews/' + dashboardTypeInd);
    }

    saveDashboardView(view: DashboardViewDto): Observable<number> {
        return this.httpService.post<number>(Api.baseApiUrl + '/api/v1/DashboardViews', view);
    }

    deleteDashboardView(viewId: number): Observable<void> {
        return this.httpService.delete<void>(Api.baseApiUrl + '/api/v1/DashboardViews/' + viewId);
    }
}

/**
 * Below are the interface DTO's specific to the Dashboard Service
 */

/** Represents the configuration of a widget */
export interface WidgetDefinitionDTO {
    id?: number;
	title: string;
	subTitle: string;
	widgetTypeInd: DashboardWidgetTypeInd;
	associatedFilterTypeInd: DashboardFilterTypeInd;
	dataEndpoint: string;
	popupHelp: WidgetPopupHelpDTO;
	loading: boolean;
	tableGraph?: boolean;
	magicTypeGraphFeature: boolean;
	restoreGraphFeature: boolean;
	widgetFilters: WidgetFilterDto[];
    sortFieldIndex?: number;
    sortFieldAscending?: boolean;
    legendSelection?: { [name: string]: boolean };
    tableFilter: string;
}

export interface WidgetFilterDto {
	filterId: string;
	filterSelection: number;
	filterOptions: WidgetFilterOptionDto[];
    label: string;
    filterType: WidgetFilterType;
    position: WidgetFilterPosition;
}

export interface WidgetFilterOptionDto {
    id: number;
    name: string;
}

export enum WidgetFilterType {
    ButtonList = 0,
    DropDownList = 1
}

export enum WidgetFilterPosition {
    Top = 0,
    Right = 1
}

/** Represents the data sent to a widget data endpoint */
export interface WidgetDataSetRequestDTO {
	organisationId: number;
	dataSetId: number;
	filters: DashboardFilterRequestDTO[];
}

/** Represents a filters selected values sent to a widget data endpoint */
export interface DashboardFilterRequestDTO {
	filterId?: string;
	filterTypeInd: DashboardFilterTypeInd;
	filterValues: number[];
}

/** Represents the data returned from a widget data endpoint */
export interface WidgetDataDTO {
	axis: AxisDefinitionDTO[];
	dimensions: DimensionDefinitionDTO[];
	data: any[];
	color: string[];
	tooltip: TooltipDto;
	graphicValues: GraphicValuesDto;
	grids: GridDto[];
	titles: TitleDto[];
    axisPointers: AxisPointerDto[];
    dataZoom: DataZoomDto[];
}

/** Represents the axis definition */
export interface AxisDefinitionDTO {
	axisLabel: string;
	axisType: AxisTypeInd;
	axisGap: number;
	rotate: number;
	max?: number;
	inverse: boolean;
	gridIndex: number;
    show: boolean;
}

/** Represents the definition of a dimension */
export interface DimensionDefinitionDTO {
	name: string;
	columnType: DimensionColumnTypeInd;
	includeInSorting: boolean;
	displayValue: boolean;
	valueFormatter: string;
	xAxisIndex: number;
	yAxisIndex: number;
	colours: string[];
	hidden: boolean;
	stack: string;
	seriesId: number;
	silent: boolean;
	seriesName: string;
	encode: EncodeDto;
	url: string;
	labelPosition: string;
	connectNulls: boolean;
	z: number;
    headerColSpan?: number;
    headerRowSpan?: number;
}

/** Represents the different types of dimensions returned in the data array of WidgetDataDTO */
export enum DimensionColumnTypeInd {
	DoNotDisplay = 0,
	AxisIdentifier = 1,
	AxisLabel = 2,
	HorizontalBar = 3,
	VerticalBar = 4,
	PieChart = 5,
	Line = 6,
	StackedBar = 7,
	TreeMap = 8,
	VerticalStackedBar = 9,
}

// Represents X or Y axis
export enum AxisTypeInd {
	YAxis = 0,
	XAxis = 1,
	AngleAxis = 2,
	RadiusAxis = 3,
}

export interface TooltipDto {
	valueFormat: ValueFormatInd;
}

export enum ValueFormatInd {
	None = 0,
	Percentage = 1,
	Currency = 2,
}

export interface GraphicValuesDto {
	title: string;
	values: string[];
}

export interface GridDto {
	containLabel: boolean;
	left: string;
	right: string;
	top: string;
	bottom: string;
}

export interface TitleDto {
	text: string;
	left: string;
	right: string;
	top: string;
	bottom: string;
}

export interface EncodeDto {
	y: number[];
	tooltip: number[];
}

export interface AxisPointerDto {
    link: AxisPointerLinkDto[];
}

export interface AxisPointerLinkDto {
    xAxisIndex: number[] | 'all';
}

export interface DataZoomDto {
    realTime: boolean;
    start: number;
    end: number;
    xAxisIndex: number[];
}

/** Represents the different types of widget supported by dashboards */
export enum DashboardWidgetTypeInd {
	Chart = 0,
	StatsLayout_1byX = 1,
	Table = 2,
	ExternalIFrame = 3,
	HeatMap = 4,
	TreeMap = 5,
	StackedArea = 6,
	Scatter = 7,
	StatsTable = 8,
}

/** Represent the different types of filters supported by dashboards */
export enum DashboardFilterTypeInd {
	Unknown = 0,
	Gender = 1,
	AgeBracket = 2,
	Sport = 3,
	GeographicLevel = 4,
	GeographicLevelSelection = 5,
	//DrillDownLevel = 6,
	Deprivation = 29,
}

export enum DashboardFilterFormInd {
	DropDown,
	Geographic,
	Slider,
}

/**
 * Represents the event emitted by a widget that could influence a filter selection
 * (e.g. Click on a gender in the gender pie chart to select a gender in the gender filter)
 * */
export interface FilterChangeEvent {
	filterTypeInd: DashboardFilterTypeInd;
	value: any;
}

export interface WidgetContainerDTO {
    id: number;
	title: string;
	subTitle: string;
	popupHelp: WidgetPopupHelpDTO;
	isCollapsed: boolean;
	widgetRows: WidgetRowDto[];
	collapseOnUpdate: boolean;
}

export interface WidgetContainerRowDTO {
	containers: WidgetContainerDTO[];
}

export interface WidgetRowDto {
	widgets: WidgetDefinitionDTO[];
}

/** Represents the definition of a dashboard */
export interface DashboardDefinitionDTO {
	typeInd: number;
	title: string;
	subTitle: string;
	organisationDataSets: any[];
	filters: BaseFilterDefinition[];
	rows: WidgetContainerRowDTO[];
	popupHelp: WidgetPopupHelpDTO;
    dashboardViews: DashboardViewDto[];
}

export interface organisationDataSetsDTO {
	id: number;
	name: string;
	dataSets: keyValueDto[];
}

export interface keyValueDto {
	id: number;
	name: string;
}

export interface organisationDataSetChange {
	organisationId: number;
	dataSetId: number;
}

/** Represents the base class all filter definitions derive from */
export interface BaseFilterDefinition {
	filterId?: string;
	filterTypeInd: DashboardFilterTypeInd;
	filterFormInd: DashboardFilterFormInd;
	label: string;
	allPlaceholder: string;
	selection: number[];

	//dropdown filter properties
	options?: any[];
	allowMultiSelect?: boolean;
	grouped?: boolean;
	minimumSelection?: number;
	minimumSelectionExclusions?: number[];

	// geographic filter properties
	topLevelId?: number;
	selectionUpdate: any;

	// slider filter properties
	min?: number;
	max?: number;
	step?: number;
	labelValue?: boolean;
}

export interface WidgetPopupHelpDTO {
	showHelpIcon: boolean;
	popupTitle: string;
	popupBodyHTML: string;
	vimeoVideoUrl?: string;
}

export interface DashboardsDTO {
	dashboardSections: DashboardSectionDTO[];
}

export interface DashboardSectionDTO {
	sectionName: string;
	dashboards: DashboardDescriptionDTO[];
}

export interface DashboardDescriptionDTO {
	typeInd: number;
	name: string;
	description: string;
	iconName: string;
	customDashboardId: number;
	order: number;
}
