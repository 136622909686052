import { KeyValue } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import {
    BarSeriesOption,
    EChartsOption,
    LegendComponentOption,
    LineSeriesOption,
    PieSeriesOption,
    SeriesOption,
    TreemapSeriesOption,
} from 'echarts';
import {
    GraphicComponentLooseOption,
    GridOption,
    TooltipOption,
    XAXisOption,
    YAXisOption,
} from 'echarts/types/dist/shared';
import {
    DashboardsServiceV2,
    DimensionColumnTypeInd,
    AxisTypeInd,
    WidgetDataDTO,
    WidgetDataSetRequestDTO,
    WidgetDefinitionDTO,
    FilterChangeEvent,
    DashboardFilterRequestDTO,
    ValueFormatInd,
    DimensionDefinitionDTO,
    WidgetFilterDto,
    WidgetFilterType,
    WidgetFilterPosition,
} from 'src/app/core/services/dashboardsv2.service';

interface SortOption {
    label: string;
    arrayIndex: number;
    ascending: boolean;
    isAlpha: boolean;
}

let arrData = [];
let maxValue, minValue;
let dataValueList;

@Component({
    selector: 'app-widget-graph',
    templateUrl: './widget-graph.component.html',
    styleUrls: ['./widget-graph.component.scss'],
    providers: [DashboardsServiceV2],
})
export class GraphWidgetComponent implements OnInit, OnChanges, AfterViewChecked {
    @Input() widgetConfig: WidgetDefinitionDTO;
    @Input() dataSetRequest: WidgetDataSetRequestDTO;
    @Input() loadWidgetData: boolean;
    @Output() filterChangeRequest = new EventEmitter<FilterChangeEvent>();

    @ViewChildren('sidePanelButtonText', { read: ElementRef }) buttonTextElements: QueryList<ElementRef>;

    httpClientService: HttpClient;
    dashboardService: DashboardsServiceV2;

    graphData: WidgetDataDTO;
    echartOptions: EChartsOption;
    sortOptions: SortOption[] = [];

    chartInstance: any;

    hasFilters: boolean;
    topFilters: WidgetFilterDto[];
    rightFilters: WidgetFilterDto[];
    keyValueOrder = () => 0;

    isMagicTypeGraphFeature: boolean;
    isRestoreGraphFeature: boolean;

    isLoaded: boolean;

    // -- eCharts Initial Configuration Options
    initOptions: { renderer: 'svg' };
    defaultColourSet: string[] = ['#735BA7', '#60C360', '#F47878', '#489292', '#F4B078'];

    originalOrder = (_a: KeyValue<string, string>, _b: KeyValue<string, string>): number => {
        return 0;
    };

    subscription: any;

    readonly WidgetFilterType = WidgetFilterType;

    constructor(httpClient: HttpClient, dashboardService: DashboardsServiceV2) {
        this.httpClientService = httpClient;
        this.dashboardService = dashboardService;
    }

    filterSelectionDto: DashboardFilterRequestDTO[];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dataSetRequest && !changes.dataSetRequest.isFirstChange()) {
            if (this.loadWidgetData) {
                this.loadData();
            } else {
                this.isLoaded = false;
            }
        }

        if (changes.loadWidgetData && !this.isLoaded && !changes.loadWidgetData.isFirstChange()) {
            if (changes.loadWidgetData.currentValue) {
                this.loadData();
            }
            //stop loading unfinished widget
            if (!changes.loadWidgetData.currentValue && this.subscription) {
                this.subscription.unsubscribe();
            }
        }
    }

    ngOnInit(): void {
        if (this.widgetConfig.widgetFilters && this.widgetConfig.widgetFilters.length > 0) {
            this.hasFilters = true;
            this.topFilters = this.widgetConfig.widgetFilters.filter(
                (filter: WidgetFilterDto) => filter.position === WidgetFilterPosition.Top
            );
            this.rightFilters = this.widgetConfig.widgetFilters.filter(
                (filter: WidgetFilterDto) => filter.position === WidgetFilterPosition.Right
            );
        }

        this.isMagicTypeGraphFeature = this.widgetConfig.magicTypeGraphFeature;
        this.isRestoreGraphFeature = this.widgetConfig.restoreGraphFeature;

        if (this.loadWidgetData) {
            this.loadData();
        }
    }

    ngAfterViewChecked(): void {
        if (
            this.buttonTextElements.some((element: ElementRef) => {
                const parentStyle = getComputedStyle(element.nativeElement.parentElement);
                const parentWidth =
                    element.nativeElement.parentElement.offsetWidth -
                    parseFloat(parentStyle.paddingLeft) -
                    parseFloat(parentStyle.paddingRight);
                return element.nativeElement.offsetWidth > parentWidth;
            })
        ) {
            this.buttonTextElements.forEach((element: ElementRef) => {
                element.nativeElement.style.display = 'block';
                element.nativeElement.style.transform = 'rotate(270deg)';
            });
        }
    }

    onChartInit(e: any) {
        this.chartInstance = e;
        this.chartInstance.on('legendselectchanged', (event) => {
            if (event.selected) {
                this.widgetConfig.legendSelection = event.selected;
            }
        });
    }

    /** Raise a filter change request event when the chart is clicked */
    onChartClick(e: any) {
        let mainChartType = this.graphData.dimensions[2].columnType;
        this.filterChangeRequest.emit({
            filterTypeInd: this.widgetConfig.associatedFilterTypeInd,
            value:
                mainChartType == DimensionColumnTypeInd.TreeMap
                    ? e.data.id
                    : mainChartType == DimensionColumnTypeInd.VerticalStackedBar && !isNaN(+e.seriesId)
                    ? parseInt(e.seriesId)
                    : e.data[1],
        });
    }

    updateWidgetFilter(filterId: string, option: number) {
        const widgetFilter = this.widgetConfig.widgetFilters.find((filter) => filter.filterId === filterId);
        if (widgetFilter.filterSelection != option) {
            widgetFilter.filterSelection = option;
            this.loadData();
        }
    }

    updateSortSelection(sortOption: SortOption) {
        var source = [];
        this.graphData.data.forEach((element) => {
            source.push(element);
        });

        this.sortData(source, sortOption);

        let option: EChartsOption = this.chartInstance.getOption();

        option.dataset = {
            dimensions: this.graphData.dimensions,
            source: source,
        };

        this.chartInstance.setOption(option);

        this.widgetConfig.sortFieldIndex = sortOption.arrayIndex;
        this.widgetConfig.sortFieldAscending = sortOption.ascending;
    }

    /** Sort chart data */
    sortData(source: any[], sortOption: SortOption) {
        let direction: number = sortOption.ascending ? 1 : -1;

        if (sortOption.isAlpha) {
            source.sort((a, b) => {
                if (a[sortOption.arrayIndex] === b[sortOption.arrayIndex]) {
                    return 0;
                }
                return (a[sortOption.arrayIndex] > b[sortOption.arrayIndex] ? 1 : -1) * direction;
            });
        } else {
            source.sort((a, b) => {
                if (+a[sortOption.arrayIndex] === +b[sortOption.arrayIndex]) {
                    return 0;
                }
                return (+a[sortOption.arrayIndex] > +b[sortOption.arrayIndex] ? 1 : -1) * direction;
            });
        }

        return source;
    }

    /** Load Chart Data */
    loadData() {
        this.isLoaded = false;
        let request: WidgetDataSetRequestDTO = JSON.parse(JSON.stringify(this.dataSetRequest));

        if (this.hasFilters) {
            this.widgetConfig.widgetFilters.forEach((widgetFilter: WidgetFilterDto) => {
                request.filters.push({
                    filterTypeInd: 0,
                    filterId: widgetFilter.filterId,
                    filterValues: [widgetFilter.filterSelection],
                });
            });
        }

        this.widgetConfig.loading = true;

        // const sleep = (ms) => new Promise(r => setTimeout(r, ms));

        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.subscription = this.dashboardService
            .getDataForWidget(this.widgetConfig.dataEndpoint, request)
            .subscribe((data: WidgetDataDTO) => {
                this.graphData = data;
                this.widgetConfig.loading = false;
                this.isLoaded = true;
                this.buildEChartsConfig();
            });
    }

    /** Build eCharts config object */
    buildEChartsConfig() {
        let chartOptions: EChartsOption = {};
        let allowSorting: boolean = this.graphData.dimensions.some((x) => x.includeInSorting);
        let mainChartType = this.graphData.dimensions[2].columnType;
        // -- prepare chart dataset
        var source = [];
        this.graphData.data.forEach((element) => {
            source.push(element);
        });

        // -- get chart options config based on main chart type
        switch (mainChartType) {
            case DimensionColumnTypeInd.HorizontalBar:
            case DimensionColumnTypeInd.StackedBar:
                chartOptions = this.horizontalBarDefaultOptions();
                if (this.graphData.data.length > 5) {
                    (<XAXisOption[]>chartOptions.xAxis).forEach((axis) => {
                        if (!axis.axisLabel) {
                            axis.axisLabel = { rotate: 20 };
                        }
                    });
                }
                break;

            case DimensionColumnTypeInd.PieChart:
                chartOptions = this.pieChartDefaultOptions();
                break;

            case DimensionColumnTypeInd.VerticalBar:
                chartOptions = this.verticalBarDefaultOptions();
                break;

            case DimensionColumnTypeInd.VerticalStackedBar:
                chartOptions = this.verticalStackedBarDefaultOptions();
                break;

            case DimensionColumnTypeInd.Line:
                chartOptions = this.horizontalBarDefaultOptions();

                break;
            case DimensionColumnTypeInd.TreeMap:
                chartOptions = this.treeMapDefaultOptions();
                break;
        }

        if (this.graphData.dimensions.some((x) => x.hidden)) {
            (<LegendComponentOption>chartOptions.legend).data = this.graphData.dimensions
                .filter((x) => !x.hidden)
                .map((x) => x.seriesName ?? x.name);
        }

        //-- configure sorting options
        if (allowSorting) {
            // reset sorting filter
            this.sortOptions = [];

            this.graphData.dimensions.forEach((dimension, index) => {
                if (dimension.includeInSorting) {
                    const isAlpha = this.graphData.data.some((x) => isNaN(+x[index]));
                    this.sortOptions.push({
                        label: dimension.name,
                        arrayIndex: index,
                        ascending: true,
                        isAlpha: isAlpha,
                    });

                    this.sortOptions.push({
                        label: dimension.name,
                        arrayIndex: index,
                        ascending: false,
                        isAlpha: isAlpha,
                    });
                }
            });

            if (this.widgetConfig.sortFieldIndex != null) {
                const sortOption = this.sortOptions.find(
                    (option: SortOption) =>
                        option.arrayIndex == this.widgetConfig.sortFieldIndex &&
                        option.ascending == this.widgetConfig.sortFieldAscending
                );
                if (sortOption) {
                    this.sortData(source, sortOption);
                }
            }
        }

        if (this.widgetConfig.legendSelection) {
            (<LegendComponentOption>chartOptions.legend).selected = this.widgetConfig.legendSelection;
        }

        // -- configure chart dataset
        chartOptions.dataset = [
            {
                dimensions: this.graphData.dimensions,
                source: source,
            },
        ];

        // -- configure the data series
        let series: SeriesOption[] = this.createSeries();
        chartOptions.series = series.length > 1 ? series : series[0];

        this.echartOptions = chartOptions;
    }

    /** Create the data series for the graphs */
    createSeries(): SeriesOption[] {
        let series: SeriesOption[] = [];

        let polar = false;
        if (
            this.graphData.axis.some(
                (x) => x.axisType === AxisTypeInd.AngleAxis || x.axisType === AxisTypeInd.RadiusAxis
            )
        ) {
            polar = true;
        }

        this.graphData.dimensions.forEach((column) => {
            let newSeries: BarSeriesOption | LineSeriesOption | PieSeriesOption | TreemapSeriesOption = {
                name: column.seriesName ?? column.name,
                seriesLayoutBy: 'column',
                label: {
                    show: column.displayValue,
                    formatter: column.valueFormatter,
                },
                silent: column.silent,
            };

            if (column.seriesId) {
                newSeries.id = column.seriesId;
            }

            if (column.colours.length > 0) newSeries.color = column.colours;

            if (column.hidden) {
                newSeries.color = 'rgba(255,255,255,0)';
                newSeries.tooltip = { show: false };
            }
            switch (column.columnType) {
                case DimensionColumnTypeInd.HorizontalBar:
                    newSeries.type = 'bar';
                    newSeries.label.position = 'top';
                    (<BarSeriesOption>newSeries).encode = { y: column.name };
                    (<BarSeriesOption>newSeries).yAxisIndex = column.yAxisIndex;

                    series.push(newSeries);
                    break;

                case DimensionColumnTypeInd.StackedBar:
                    newSeries.type = 'bar';
                    newSeries.label.position = <'top' | 'bottom' | 'inside'>column.labelPosition ?? 'top';
                    (<BarSeriesOption>newSeries).encode = { y: column.name };
                    (<BarSeriesOption>newSeries).stack = column.stack ?? 'one';
                    if (polar) {
                        newSeries.coordinateSystem = 'polar';
                        (<BarSeriesOption>newSeries).encode = { angle: column.name };
                        newSeries.emphasis = {
                            focus: 'series',
                        };
                        newSeries.itemStyle = {
                            borderRadius: 3,
                            borderColor: '#fff',
                            borderWidth: 2,
                        };
                        (<BarSeriesOption>newSeries).barCategoryGap = '50%';
                    } else {
                        (<BarSeriesOption>newSeries).encode = { y: column.name };
                    }

                    series.push(newSeries);
                    break;

                case DimensionColumnTypeInd.VerticalBar:
                    newSeries.type = 'bar';
                    newSeries.label.position = 'right';
                    (<BarSeriesOption>newSeries).encode = { x: column.name };
                    (<BarSeriesOption>newSeries).yAxisIndex = column.yAxisIndex;

                    series.push(newSeries);
                    break;

                case DimensionColumnTypeInd.VerticalStackedBar:
                    newSeries.type = 'bar';
                    newSeries.label.position = 'right';
                    (<BarSeriesOption>newSeries).encode = { x: column.name };
                    (<BarSeriesOption>newSeries).yAxisIndex = column.yAxisIndex;
                    (<BarSeriesOption>newSeries).stack = column.stack ?? 'one';
                    newSeries.emphasis = {
                        focus: 'series',
                    };

                    series.push(newSeries);
                    break;

                case DimensionColumnTypeInd.PieChart:
                    if (series.length == 0) {
                        (<PieSeriesOption>newSeries).radius = ['40%', '70%'];
                    } else if (series.length == 1) {
                        (<PieSeriesOption>series[0]).radius = ['25%', '45%'];
                        (<PieSeriesOption>series[0]).z = 5;
                        (<PieSeriesOption>newSeries).radius = ['50%', '70%'];
                    } else if (series.length == 2) {
                        (<PieSeriesOption>series[0]).radius = ['15%', '30%'];
                        (<PieSeriesOption>series[1]).radius = ['35%', '50%'];
                        (<PieSeriesOption>series[1]).z = 4;
                        (<PieSeriesOption>newSeries).radius = ['55%', '70%'];
                    }

                    newSeries.type = 'pie';

                    (<PieSeriesOption>newSeries).center = ['50%', '50%'];
                    newSeries.itemStyle = {
                        borderRadius: 3,
                        borderColor: '#fff',
                        borderWidth: 2,
                    };
                    (<PieSeriesOption>newSeries).encode = {
                        itemName: this.graphData.dimensions[0].name,
                        value: column.name,
                    };

                    series.push(newSeries);
                    break;

                case DimensionColumnTypeInd.Line:
                    newSeries.type = 'line';
                    (<LineSeriesOption>newSeries).encode = { ...{ y: column.name }, ...column.encode };
                    (<LineSeriesOption>newSeries).xAxisIndex = column.xAxisIndex;
                    (<LineSeriesOption>newSeries).yAxisIndex = column.yAxisIndex;
                    (<LineSeriesOption>newSeries).showSymbol = false;
                    (<LineSeriesOption>newSeries).connectNulls = column.connectNulls;
                    if (column.z) {
                        (<LineSeriesOption>newSeries).z = column.z;
                    }

                    series.push(newSeries);
                    break;

                case DimensionColumnTypeInd.TreeMap:
                    arrData = [];
                    this.graphData.data.forEach((a) => arrData.push({ name: a[0], id: a[1], value: a[2] }));

                    (<TreemapSeriesOption>newSeries).type = 'treemap';
                    (<TreemapSeriesOption>newSeries).roam = 'move'; // disable scroll zoom
                    (<TreemapSeriesOption>newSeries).label = {
                        show: true,
                        formatter: '{b}',
                    };

                    (<TreemapSeriesOption>newSeries).itemStyle = {
                        borderColor: '#fff',
                    };

                    (<TreemapSeriesOption>newSeries).levels = this.getLevelOption();
                    (<TreemapSeriesOption>newSeries).data = arrData;
                    (<TreemapSeriesOption>newSeries).nodeClick = 'link';
                    (<TreemapSeriesOption>newSeries).breadcrumb = { show: false, emptyItemWidth: 0 };

                    series.push(newSeries);
                    break;
            }
        });

        return series;
    }

    /** Set the common chart configuration options */
    defaultEChartOptions(): EChartsOption {
        const defaultOption: EChartsOption = {
            title: this.graphData.titles,
            color: this.graphData.color.length > 0 ? this.graphData.color : this.defaultColourSet,
            toolbox: {
                show: true,
                feature: {
                    magicType: { show: false, type: ['line', 'bar'] },
                    dataZoom: { show: false },
                    mark: { show: false },
                    restore: { show: false },
                    saveAsImage: {
                        show: true,
                        name: ('ActiveXchange_' + this.widgetConfig.title.replace(' ', '_') + '_Export').toLowerCase(),
                    },
                },
            },
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'shadow',
                },
                appendToBody: true,
            },
            legend: {
                top: '6px',
                left: 'center',
                show: true,
                type: 'scroll',
                padding: [5, 120, 5, 120],
            },

            grid: this.graphData.grids ?? {
                containLabel: true,
                left: 55,
                right: 55,
                bottom: 35,
            },
            axisPointer: this.graphData.axisPointers,
            dataZoom: this.graphData.dataZoom,
        };
        switch (this.graphData.tooltip?.valueFormat) {
            case ValueFormatInd.Percentage: {
                (<TooltipOption>defaultOption.tooltip).valueFormatter = (value) => (value != null ? value + '%' : '-');
                break;
            }
            case ValueFormatInd.Currency: {
                (<TooltipOption>defaultOption.tooltip).valueFormatter = (value) => '$' + value;
                break;
            }
        }
        return defaultOption;
    }

    /** Defaults for a Pie Chart */
    pieChartDefaultOptions(): EChartsOption {
        let result = this.defaultEChartOptions();

        result.legend = {
            type: 'scroll',
            orient: 'horizontal',
            right: 10,
            bottom: 10,
        };

        const dimensionCount = this.graphData.dimensions.filter(
            (x) => x.columnType === DimensionColumnTypeInd.PieChart
        ).length;
        if (dimensionCount > 1) {
            result.graphic = [];
            this.graphData.dimensions
                .filter((x) => x.columnType === DimensionColumnTypeInd.PieChart)
                .forEach((dimension: DimensionDefinitionDTO, index: number) => {
                    const label =
                        dimension.name +
                        '\n' +
                        this.graphData.data
                            .reduce((sum, currentValue) => sum + currentValue[index + 2], 0)
                            .toLocaleString();
                    (<GraphicComponentLooseOption[]>result.graphic).push({
                        type: 'group',
                        bottom: (dimensionCount == 2 ? 67.5 + index * 12.5 : 61.25 + index * 10) + '%',
                        left: '50%',
                        silent: true,
                        textConfig: {
                            position: 'insideLeft',
                        },
                        textContent: {
                            z: 6,
                            style: {
                                text: label,
                                x: 5,
                                verticalAlign: 'middle',
                            },
                        },
                    });
                });
            return result;
        }

        const total = this.graphData.data.reduce((sum, currentValue) => sum + currentValue[2], 0);

        result.graphic = {
            type: 'text',
            left: 'center',
            top: 'center',
            style: {
                text: total,
                align: 'center',
                fill: '#555',
                width: 30,
                height: 30,
                fontSize: 22,
            },
        };

        return result;
    }

    /** Defaults for a vertical bar graph */
    verticalBarDefaultOptions(): EChartsOption {
        let result = this.defaultEChartOptions();
        (<TooltipOption>result.tooltip).trigger = 'axis';
        this.setAxisOptions(result, 'value', 'category');

        (<any>result.toolbox).feature.magicType.show = true;
        (<any>result.toolbox).feature.restore.show = true;

        return result;
    }

    /** Defaults for a vertical stacked bar graph */
    verticalStackedBarDefaultOptions(): EChartsOption {
        let result = this.defaultEChartOptions();
        (<GridOption>result.grid).left = 30;
        (<GridOption>result.grid).right = 60;
        this.setAxisOptions(result, 'value', 'category');

        (<any>result.toolbox).feature.magicType.show = true;
        (<any>result.toolbox).feature.restore.show = true;

        return result;
    }

    /** Defaults for a horizontal bar graph */
    horizontalBarDefaultOptions(): EChartsOption {
        let result = this.defaultEChartOptions();
        (<TooltipOption>result.tooltip).trigger = 'axis';

        if (
            this.graphData.axis.some(
                (axis) => axis.axisType === AxisTypeInd.AngleAxis || axis.axisType === AxisTypeInd.RadiusAxis
            )
        ) {
            result.angleAxis = { type: 'value' };
            result.radiusAxis = {
                type: 'category',
                z: 2,
                axisLabel: {
                    align: 'left',
                    color: '#fff',
                    textBorderColor: '#999',
                    textBorderWidth: 1,
                    margin: -8,
                    textShadowColor: '#000',
                    textShadowBlur: 1,
                    textShadowOffsetX: 1,
                    textShadowOffsetY: 1,
                },
            };
            result.polar = {};
        } else {
            this.setAxisOptions(result, 'category', 'value');
        }
        if (this.graphData.graphicValues) {
            result.graphic = [
                {
                    type: 'group',
                    top: '12%',
                    right: '10%',
                    silent: true,
                    children: [],
                },
            ];
            if (this.graphData.graphicValues.title) {
                (<any>result.graphic[0]).children.push({
                    type: 'text',
                    z: 4,
                    style: {
                        text: this.graphData.graphicValues.title,
                        font: 'bold 12pt sans-serif',
                        align: 'center',
                    },
                });
            }
            this.graphData.graphicValues.values.forEach((value, index) => {
                (<any>result.graphic[0]).children.push({
                    type: 'text',
                    y: 18 * (index + (this.graphData.graphicValues.title ? 1 : 0)),
                    z: 4,
                    style: {
                        text: value,
                        font: 'bold 12pt sans-serif',
                        fill: result.color[index],
                        align: 'center',
                    },
                });
            });
        }

        (<any>result.toolbox).feature.magicType.show = true;
        (<any>result.toolbox).feature.restore.show = true;

        return result;
    }

    /** Create data for TreeMap graph */
    treeMapDefaultOptions(): EChartsOption {
        // set chart colour range
        let colourRange = this.graphData.dimensions[2].colours;

        // get all values from the data collection
        dataValueList = this.graphData.data.map((a) => a[2]);

        // get max value from the data collection
        maxValue = dataValueList.reduce((op, item) => (op = op > item ? op : item), 0);
        // get min value from the data collection
        minValue = dataValueList.reduce((op, item) => (op = op < item ? op : item), 0);

        // set eChart properties
        this.echartOptions = {
            tooltip: {
                position: 'top',
            },
            grid: {
                height: '100%',
                top: '0%',
            },
            visualMap: {
                min: minValue, // define min value for color dsiplay
                max: maxValue, // define max value for color display
                show: false,
                inRange: {
                    color: colourRange,
                },
            },
        };

        return this.echartOptions;
    }

    setAxisOptions(
        echartOption: EChartsOption,
        xAxisType: 'value' | 'category',
        yAxisType: 'value' | 'category'
    ): void {
        const yAxes: YAXisOption[] = [];
        const xAxes: XAXisOption[] = [];

        this.graphData.axis.forEach((axis) => {
            switch (axis.axisType) {
                case AxisTypeInd.XAxis: {
                    const max = axis.max
                        ? xAxisType == 'value'
                            ? axis.max
                            : Math.min(axis.max, this.graphData.data.length) - 1
                        : null;

                    xAxes.push({
                        type: xAxisType,
                        name: axis.axisLabel,
                        nameLocation: 'middle',
                        nameGap: axis.axisGap == null ? 30 : axis.axisGap,
                        axisLabel: { rotate: axis.rotate },
                        max: max,
                        inverse: axis.inverse,
                        gridIndex: axis.gridIndex,
                        show: axis.show,
                    });
                    break;
                }
                case AxisTypeInd.YAxis: {
                    const max = axis.max
                        ? yAxisType == 'value'
                            ? axis.max
                            : Math.min(axis.max, this.graphData.data.length) - 1
                        : null;

                    yAxes.push({
                        type: yAxisType,
                        name: axis.axisLabel,
                        nameLocation: 'middle',
                        nameGap: axis.axisGap == null ? 55 : axis.axisGap,
                        axisLabel: { rotate: axis.rotate },
                        max: max,
                        inverse: axis.inverse,
                        gridIndex: axis.gridIndex,
                        show: axis.show,
                    });
                    break;
                }
            }
        });
        if (xAxes.length === 0) {
            xAxes.push({ type: xAxisType });
        }
        if (yAxes.length === 0) {
            yAxes.push({ type: yAxisType });
        }
        echartOption.xAxis = xAxes;
        echartOption.yAxis = yAxes;
    }

    // function for setting gap between each data records
    getLevelOption() {
        return [
            {
                itemStyle: {
                    borderWidth: 0,
                    gapWidth: 2,
                },
            },
            {
                itemStyle: {
                    gapWidth: 1,
                    borderColor: '#fff',
                },
            },
            {
                colorSaturation: [0.35, 0.5],
                itemStyle: {
                    gapWidth: 1,
                    borderColorSaturation: 0.6,
                },
            },
        ];
    }
}
