<div class="container-fluid container-fluid--sePadding container-dashboard" *ngIf="loadingIndicator">
    <app-loading-indicator text="Warming up your dashboard"></app-loading-indicator>
</div>

<div class="container-fluid container-fluid--sePadding container-dashboard" *ngIf="!loadingIndicator">
    <app-page-heading
        [iconName]="'insert_chart'"
        [title]="dashboardConfig?.title"
        [subTitle]="dashboardConfig?.subTitle">
        <app-icon-button
            [icon]="'arrow_back'"
            [text]="'BACK'"
            color="grey"
            [size]="'large'"
            (buttonClicked)="back()"></app-icon-button>
        <app-icon-button
            *ngIf="dashboardMode === 'data' && !isProDashboard"
            class="d-none d-md-block"
            [icon]="'picture_as_pdf'"
            [text]="'EXPORT PDF'"
            [size]="'large'"
            (click)="exportPDF()">
        </app-icon-button>
        <app-icon-button
            *ngIf="dashboardConfig.popupHelp?.showHelpIcon"
            icon="live_help"
            color="grey"
            size="large"
            (click)="
                showHelpModal(
                    dashboardConfig.popupHelp.popupTitle,
                    dashboardConfig.popupHelp.popupBodyHTML,
                    dashboardConfig.popupHelp.vimeoVideoUrl
                )
            "
            matTooltip="Help"
            matTooltipShowDelay="500">
        </app-icon-button>
    </app-page-heading>

    <app-empty-state
        *ngIf="dashboardMode === 'nodata'"
        iconName="analytics"
        notificationText="Nothing to see here yet!"
        callToActionText="Upload your first data set to start exploring insights.">
    </app-empty-state>

    <div *ngIf="dashboardConfig && dashboardMode === 'data'">
        <!-- Dashboard Filters -->
        <app-filter-container
            *ngIf="dashboardConfig.typeInd !== 50"
            [config]="dashboardConfig"
            (orgDataSetChanged)="onDataSetChanged($event)"
            (filtersChanged)="onFilterChanged()">
        </app-filter-container>

        <!-- Widget Rows -->
        <div #printDiv id="printDiv">
            <section *ngFor="let containerRow of dashboardConfig.rows" class="container-row">
                <section *ngFor="let widgetContainer of containerRow.containers" class="widget-container">
                    <div class="widget-container__titlebar">
                        <div class="title">{{ widgetContainer.title }}</div>
                        <div class="buttons" *ngIf="containerRow.containers.length == 1">
                            <mat-icon
                                [hidden]="widgetContainer.isCollapsed"
                                (click)="toggleContainerCollapse(widgetContainer, true)"
                                matTooltip="Collapse Panel"
                                matTooltipPosition="left"
                                matTooltipShowDelay="500"
                                class="toggleButton">
                                expand_less
                            </mat-icon>

                            <mat-icon
                                [hidden]="!widgetContainer.isCollapsed"
                                (click)="toggleContainerCollapse(widgetContainer, false)"
                                matTooltip="Show Panel"
                                matTooltipPosition="left"
                                matTooltipShowDelay="500"
                                class="toggleButton">
                                expand_more
                            </mat-icon>
                        </div>
                    </div>
                    <div class="widget-container__subtitlebar">
                        <div class="subtitle">{{ widgetContainer.subTitle }}</div>
                        <div class="buttons">
                            <mat-icon
                                *ngIf="widgetContainer.popupHelp && widgetContainer.popupHelp.showHelpIcon"
                                matTooltip="Help"
                                matTooltipPosition="left"
                                matTooltipShowDelay="500"
                                class="helpButton"
                                (click)="
                                    showHelpModal(
                                        widgetContainer.popupHelp.popupTitle,
                                        widgetContainer.popupHelp.popupBodyHTML,
                                        widgetContainer.popupHelp.vimeoVideoUrl
                                    )
                                ">
                                live_help
                            </mat-icon>
                        </div>
                    </div>
                    <div
                        class="widget-container__widgets"
                        [ngClass]="{
                            singleWidget:
                                widgetContainer.widgetRows.length == 1 &&
                                widgetContainer.widgetRows[0].widgets.length == 1
                        }"
                        [hidden]="widgetContainer.isCollapsed">
                        <div *ngFor="let widgetRow of widgetContainer.widgetRows" class="widget-row">
                            <div *ngFor="let widget of widgetRow.widgets" class="widget">
                                <h2 *ngIf="widgetContainer.widgetRows.length > 1 || widgetRow.widgets.length > 1">
                                    {{ widget.title }}
                                </h2>
                                <div class="widget__body" [ngSwitch]="widget.widgetTypeInd">
                                    <app-widget-graph
                                        *ngSwitchCase="0"
                                        [widgetConfig]="widget"
                                        [dataSetRequest]="widgetDataSetRequestDTO"
                                        [loadWidgetData]="!widgetContainer.isCollapsed"
                                        (filterChangeRequest)="onFilterChangeRequestFromWidget(widget, $event)">
                                    </app-widget-graph>

                                    <app-widget-stats
                                        *ngSwitchCase="1"
                                        [dataSetRequest]="widgetDataSetRequestDTO"
                                        [widgetConfig]="widget"
                                        [loadWidgetData]="!widgetContainer.isCollapsed">
                                    </app-widget-stats>

                                    <app-widget-table
                                        *ngSwitchCase="2"
                                        [dataSetRequest]="widgetDataSetRequestDTO"
                                        [widgetConfig]="widget"
                                        [loadWidgetData]="!widgetContainer.isCollapsed"
                                        (mapDialogButtonClicked)="
                                            mapDialogButtonClicked$.next({ url: $event, widget: widget })
                                        ">
                                    </app-widget-table>

                                    <div *ngSwitchCase="3" class="ratio ratio-16x9">
                                        <iframe [src]="widget.dataEndpoint | safeUrl"></iframe>
                                    </div>

                                    <app-widgetheatmap-graph
                                        *ngSwitchCase="4"
                                        [dataSetRequest]="widgetDataSetRequestDTO"
                                        [widgetConfig]="widget"
                                        [loadWidgetData]="!widgetContainer.isCollapsed">
                                    </app-widgetheatmap-graph>

                                    <app-widget-graph
                                        *ngSwitchCase="5"
                                        [dataSetRequest]="widgetDataSetRequestDTO"
                                        [widgetConfig]="widget"
                                        [loadWidgetData]="!widgetContainer.isCollapsed"
                                        (filterChangeRequest)="onFilterChangeRequestFromWidget(widget, $event)">
                                    </app-widget-graph>

                                    <app-stacked-graph
                                        *ngSwitchCase="6"
                                        [dataSetRequest]="widgetDataSetRequestDTO"
                                        [widgetConfig]="widget"
                                        [loadWidgetData]="!widgetContainer.isCollapsed">
                                    </app-stacked-graph>

                                    <app-widget-scatter-graph
                                        *ngSwitchCase="7"
                                        [dataSetRequest]="widgetDataSetRequestDTO"
                                        [widgetConfig]="widget"
                                        [loadWidgetData]="!widgetContainer.isCollapsed">
                                    </app-widget-scatter-graph>

                                    <app-widget-stats-table
                                        *ngSwitchCase="8"
                                        [dataSetRequest]="widgetDataSetRequestDTO"
                                        [widgetConfig]="widget"
                                        [loadWidgetData]="!widgetContainer.isCollapsed">
                                    </app-widget-stats-table>

                                    <div [hidden]="!widget.loading" class="loadingOverlay">
                                        <img src="/assets/images/dashboard-loader.gif" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    </div>
</div>
