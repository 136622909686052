// -- Angular Core Modules
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// -- SportsEye Application Modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { TokenInterceptor } from './core/interceptors/tokenInterceptor';
import { EtlModule } from './modules/etl/etl.module';
import { AnalyticsService } from './core/services/analytics/analytics.service';
import { ReportsModule } from './modules/reports/reports.module';
import { HeaderComponent } from './core/components/header/header.component';
import { HomeComponent } from './core/components/home/home.component';
import { LayoutComponent } from './core/components/layout/layout.component';
import { LoginComponent } from './core/components/login/login.component';
import { LoginShellComponent } from './core/components/login-shell/login-shell.component';
import { PasswordForgotComponent } from './core/components/password-forgot/password-forgot.component';
import { PasswordChangeComponent } from './core/components/password-change/password-change.component';
import { PasswordResetComponent } from './core/components/password-reset/password-reset.component';
import { SettingsComponent } from './core/components/settings/settings.component';
import { EmailSubscriptionsComponent } from './core/components/email-subscriptions/email-subscriptions.component';
import { DashboardsV2Module } from './modules/dashboardsv2/dashboardsv2.module';

@NgModule({
    declarations: [
        AppComponent,
        EmailSubscriptionsComponent,
        HeaderComponent,
        HomeComponent,
        LayoutComponent,
        LoginComponent,
        LoginShellComponent,
        PasswordForgotComponent,
        PasswordChangeComponent,
        PasswordResetComponent,
        SettingsComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SharedModule,
        ReportsModule,
        EtlModule,
        DashboardsV2Module,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        AnalyticsService,
        Title,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
