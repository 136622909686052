<div class="filter__container" *ngFor="let geographicLevel of options">
	<app-multi-select-filter
		(selectionChange)="selectionChanged(geographicLevel.levelId, $event)"
		[options]="filteredGeography[geographicLevel.levelId]"
							[label]="geographicLevel.levelName"
							[placeholder]="geographicLevel.allPlaceholder"
		[allowMultiple]="true"
		[selection]="levelSelection[geographicLevel.levelId]">
	</app-multi-select-filter>
</div>
