<section class="widget-container" [class.widget-container--filters]="hasFilters">
    <div *ngIf="hasFilters" class="filter-panel">
        <div class="filter-label">
            {{widgetConfig.widgetFilters[0].label}}
        </div>
		<button
			type="button"
			*ngFor="let filter of widgetConfig.widgetFilters[0].filterOptions | keyvalue"
			(click)="updateWidgetFilter(widgetConfig.widgetFilters[0].filterId, +filter.key)"
			[class.active]="widgetConfig.widgetFilters[0].filterSelection === +filter.key">
			<span #sidePanelButtonText>{{ filter.value }}</span>
		</button>
	</div>

    <div class="overflow-x-auto pb-2">
        <table mat-table [dataSource]="dataSource" matSort *ngIf="dataSource" class="widget-data-table">
            <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef" [sticky]="column.sticky">
                <ng-container *ngIf="column.headerRowSpan">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [attr.rowspan]="column.headerRowSpan" [class]="column.class">{{ column.header }}</th>
                </ng-container>
                <ng-container *ngIf="!column.headerRowSpan">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        [class]="column.class"
                        [arrowPosition]="column.class == 'number' ? 'before' : 'after'"
                        [ngStyle]="{'display': column.displayHeader ? 'table-cell' : 'none'}">
                        <ng-container *ngIf="!column.headerUrl">
                            {{ column.header }}
                        </ng-container>
                        <span *ngIf="column.headerUrl" class="dialog-button" (click)="openMapDialog($event, column.headerUrl)">
                            {{ column.header }}
                        </span>
                    </th>
                    <td mat-cell *matCellDef="let row" [class]="column.class" [style.background-color]="column.cellColour(row)">
                        <div *ngIf="column.isBar" class="innerBar" [style.width.%]="column.cell(row)"></div>
                        <span *ngIf="column.cellUrl(row)" class="dialog-button" (click)="openMapDialog($event, column.cellUrl(row))">
                            {{ column.cell(row) }}
                        </span>
                        <ng-container *ngIf="!column.isBar && !column.cellUrl(row)">
                            {{ column.cell(row) }}
                        </ng-container>
                    </td>
                </ng-container>
            </ng-container>

            <ng-container *ngFor="let header of headerGroups" [matColumnDef]="header.columnDef">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="header.colSpan" class="header-group"> {{header.name}} </th>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedHeaderGroups"></tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        </table>
    </div>

    <div class="table-footer">
        <mat-form-field class="filter-input" appearance="fill" subscriptSizing="dynamic">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" #input />
        </mat-form-field>
        <mat-paginator pageSize="20" showFirstLastButtons hidePageSize></mat-paginator>
    </div>
</section>