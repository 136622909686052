<div class="d-flex gap-4 p-5 h-100" #statsTable>
	<div
		*ngFor="let col of widgetData?.dimensions; index as i"
		class="d-flex flex-column stats-column"
		[class.flex-grow-1]="i > 0">
		<div class="header-row">{{ col.name }}</div>
		<ng-container *ngIf="i > 0; else staticText">
			<div
				*ngFor="let row of widgetData?.data"
				class="d-flex flex-grow-1 align-items-center justify-content-end value-container">
				<span
					#autoSize
					class="value"
					[style.color]="i > 0 ? defaultColourSet[i - 1] : null"
					[innerHTML]="formatString(row[i])">
				</span>
			</div>
		</ng-container>
		<ng-template #staticText>
			<div *ngFor="let row of widgetData?.data" class="d-flex flex-grow-1 align-items-center">
				<span class="label" [innerHTML]="formatString(row[i])"></span>
			</div>
		</ng-template>
	</div>
</div>
