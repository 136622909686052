<header>
    <h2 class="d-flex gap-2 align-items-center">
        <mat-icon>dashboard</mat-icon>
        <span>{{ isEdit ? 'Edit' : 'Save' }} View</span>
    </h2>
    <button
        mat-icon-button
        aria-label="close dialog"
        disableRipple
        mat-dialog-close
        matTooltip="Close"
        [matTooltipShowDelay]="500"
        tabindex="-1">
        <mat-icon>close</mat-icon>
    </button>
</header>

<span *ngIf="loading; else loaded" class="spinner"><mat-spinner></mat-spinner></span>

<ng-template #loaded>
    <content *ngIf="form" [formGroup]="form">
        <section>
            <div class="container">
                <div class="row" *ngIf="!isEdit">
                    <mat-form-field appearance="fill" class="col">
                        <mat-label>Save View As</mat-label>
                        <mat-select
                            matNativeControl
                            formControlName="dashboardView"
                            (selectionChange)="handleViewChange()">
                            <mat-option [value]="0">-- New View --</mat-option>
                            <mat-option *ngFor="let view of existingViews" [value]="view.id">{{
                                view.name
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field appearance="fill" class="col">
                        <mat-label>View Name</mat-label>
                        <input matInput formControlName="name" maxlength="100" />
                    </mat-form-field>
                </div>

                <div class="row align-items-center">
                    <mat-checkbox formControlName="isDefault" class="col">
                        <label>
                            <div>Default View</div>
                            <div class="mat-small">View loads on initial open (1 per user)</div>
                        </label>
                    </mat-checkbox>
                </div>
            </div>
        </section>
        <section>
            <b>Sharing</b>
            <mat-divider></mat-divider>
            <div class="container">
                <div class="row align-items-center">
                    <mat-form-field appearance="fill" class="col">
                        <mat-label>Sharing Level</mat-label>
                        <mat-select
                            matNativeControl
                            formControlName="sharingLevel"
                            (selectionChange)="handleSharingChange()">
                            <mat-option *ngIf="view.isCreator" [value]="sharingLevel.Private">Private</mat-option>
                            <mat-option [value]="sharingLevel.Organisation">Organisation</mat-option>
                            <mat-option [value]="sharingLevel.UserGroups">User Groups</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-checkbox formControlName="isReadOnly" class="col-auto">Read Only</mat-checkbox>
                </div>
                <div *ngIf="selectedSharingLevel === sharingLevel.UserGroups" class="row">
                    <mat-form-field appearance="fill" class="col">
                        <mat-label>User Groups</mat-label>
                        <mat-select matNativeControl formControlName="userGroups" multiple #userGroupsSelect>
                            <mat-option *ngFor="let userGroup of userGroups" [value]="userGroup">{{ userGroup.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </section>
    </content>

    <footer *ngIf="form">
        <app-icon-button
            matTooltip="Remove"
            [matTooltipShowDelay]="500"
            icon="delete"
            (buttonClicked)="delete()"
            color="red"
            size="small"
            [disabled]="!canEdit"
            *ngIf="isEdit">
        </app-icon-button>

        <app-icon-button icon="cancel" color="grey" size="small" text="CLOSE" mat-dialog-close></app-icon-button>
        <app-icon-button
            icon="check_circle"
            (buttonClicked)="save()"
            color="green"
            size="small"
            text="SAVE"
            [disabled]="!form.dirty || !form.valid"
            [showProgress]="isSaving">
        </app-icon-button>
    </footer>
</ng-template>
