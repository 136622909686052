<span *ngIf="loading; else loaded" class="spinner-overlay"><mat-spinner></mat-spinner></span>

<ng-template #loaded>
	<form [formGroup]="form" (ngSubmit)="onSubmit()" #loaded>
		<div class="container-fluid">
			<div class="row justify-content-end mb-3 button-row">
				<app-icon-button
					icon="check_circle"
					text="UPDATE SUBSCRIPTIONS"
					size="small"
					class="col-auto"
					type="submit"
					[showProgress]="saving"
					[disabled]="!form.dirty || !form.valid"></app-icon-button>
			</div>
			<div class="row">
				<mat-accordion class="col" multi displayMode="flat">
					<mat-expansion-panel
						class="mat-elevation-z0"
						[ngClass]="{ expanded: isActiveControl.value }"
						disabled
						[expanded]="isActiveControl.value"
						hideToggle>
						<mat-expansion-panel-header expandedHeight="3rem">
							<div class="d-flex align-items-center">
								<mat-checkbox
									color="primary"
									formControlName="isActive"
									(click)="$event.stopPropagation()"
									(change)="movementSubscriptionChange($event.checked)">
									Subscribe to Monthly Movement Update Email
								</mat-checkbox>
							</div>
						</mat-expansion-panel-header>
						<div class="row gap-3">
							<div class="col-12 col-md-auto">
								<div class="row">
									<h3>Sites and Collections</h3>
								</div>
								<div class="row">
									<span class="mat-small">Select the items that you wish to subscribe to:</span>
								</div>
								<div class="row">
									<div>
										<mat-selection-list
											color="primary"
											formControlName="itemSelections"
											(selectionChange)="itemSelectionChange()">
											<mat-accordion displayMode="flat" multi>
												<mat-expansion-panel
													class="remove-expansion-panel-body-padding mat-elevation-z0"
													*ngFor="let group of groupCheckboxStates">
													<mat-expansion-panel-header class="px-3" collapsedHeight="2rem" expandedHeight="2rem">
														<mat-checkbox
															color="primary"
															[checked]="group.isChecked"
															[indeterminate]="group.isIndeterminate"
															(change)="toggleSelectAllGroup($event.checked, group)"
															(click)="$event.stopPropagation()">
															{{ group.name ?? 'Ungrouped' }}
														</mat-checkbox>
													</mat-expansion-panel-header>
													<ng-container *ngFor="let item of movementMonitoringItems">
														<mat-list-option
															*ngIf="item.group?.id === group.id || (!item.group && !group.id)"
															checkboxPosition="before"
															[value]="item"
															[class.grey-checkbox]="group.isChecked && item.group">
															{{ item.name }}
														</mat-list-option>
													</ng-container>
												</mat-expansion-panel>
											</mat-accordion>
										</mat-selection-list>
									</div>
								</div>
							</div>
							<app-edit-list
								class="col-12 col-md-auto"
								titleText="Additional Recipients"
								subtitle="Add additional emails to recieve the subscription:"
								[items]="additionalRecipients"
								itemType="recipient"
								[email]="true"
								[required]="true"
								(itemsChanged)="additionalRecipientsChanged()">
							</app-edit-list>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</div>
		</div>
	</form>
</ng-template>
