<section class="widget-container d-flex flex-column">
    <!-- Top Filters-->
    <div *ngIf="hasFilters && topFilters.length > 0" class="filter-panel filter-panel__top">
        <div
            *ngFor="let filter of topFilters"
            class="filter-group"
            [ngClass]="'filter-group__' + WidgetFilterType[filter.filterType]">
            <ng-container *ngIf="filter.filterType === WidgetFilterType.ButtonList">
                <div class="button-group">
                    <div *ngIf="filter.label" class="filter-label">
                        {{ filter.label }}
                    </div>
                    <button
                        type="button"
                        *ngFor="let filterOption of filter.filterOptions"
                        (click)="updateWidgetFilter(filter.filterId, filterOption.id)"
                        [class.active]="filter.filterSelection === filterOption.id">
                        <span #sidePanelButtonText>{{ filterOption.name }}</span>
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="filter.filterType === WidgetFilterType.DropDownList">
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                    <mat-select
                        [value]="filter.filterSelection"
                        (selectionChange)="updateWidgetFilter(filter.filterId, $event.value)">
                        <mat-option
                            class="filter-option"
                            *ngFor="let filterOption of filter.filterOptions"
                            [value]="filterOption.id">
                            {{ filterOption.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
        </div>
    </div>

    <div class="d-flex position-relative flex-grow-1">
        <!-- eCharts Component  flex-grow-1 px-3 min-width-0  -->
        <div
            echarts
            class="chart"
            [options]="echartOptions"
            [initOpts]="initOptions"
            [autoResize]="true"
            (chartInit)="onChartInit($event)"
            (chartClick)="onChartClick($event)"></div>

        <!-- Side Filters -->
        <div *ngIf="hasFilters && rightFilters.length > 0" class="filter-panel filter-panel__right">
            <div
                *ngFor="let filter of rightFilters"
                class="filter-group"
                [ngClass]="'filter-group__' + WidgetFilterType[filter.filterType]">
                <button
                    type="button"
                    *ngFor="let filterOption of filter.filterOptions"
                    (click)="updateWidgetFilter(filter.filterId, filterOption.id)"
                    [class.active]="filter.filterSelection === filterOption.id">
                    <span #sidePanelButtonText>{{ filterOption.name }}</span>
                </button>
            </div>
        </div>

        <!-- Sorting Menu -->
        <button
            *ngIf="sortOptions.length > 0"
            class="sortButton"
            mat-button
            [matMenuTriggerFor]="sortMenu"
            aria-label="Sort">
            <mat-icon>sort</mat-icon>
        </button>
        <mat-menu #sortMenu="matMenu" class="no-max-width">
            <button mat-menu-item *ngFor="let sortOption of sortOptions" (click)="updateSortSelection(sortOption)">
                <div class="d-flex align-items-center">
                    <mat-icon>
                        {{
                            sortOption.isAlpha
                                ? 'sort_by_alpha'
                                : sortOption.ascending
                                ? 'arrow_upward'
                                : 'arrow_downward'
                        }}
                    </mat-icon>
                    <span>{{ sortOption.label + (sortOption.ascending ? ' (Ascending)' : ' (Descending)') }}</span>
                </div>
            </button>
        </mat-menu>
    </div>
</section>
