import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
	loading: boolean;
	serverErrors: string[] = [];
	navOpened = true;

	menuItems: { icon: string; text: string }[] = [{ icon: 'lock', text: 'Change Password' }];
	selectedItem = this.menuItems[0];

	constructor(private authService: AuthService) {}

	ngOnInit(): void {
		let featuresFlag = this.authService.getFeaturesFlag();
		if ((featuresFlag & 4) > 0) {
			this.menuItems.push({ icon: 'mail', text: 'Email Subscriptions' });
		}
	}

	menuChange(selectedItem: { icon: string; text: string }) {
		this.selectedItem = selectedItem;
		this.navOpened = false;
	}

	toggleNav(): void {
		this.navOpened = !this.navOpened;
	}

	showErrors(errors: string[]): void {
		this.serverErrors = errors;
	}
}
