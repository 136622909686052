import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { KeyValuePair } from 'src/app/shared/models/keyvaluepair';

@Component({
	selector: 'app-singleselect-container',
	templateUrl: 'filter-singleselect.component.html',
	styleUrls: ['./filter-singleselect.component.scss'],
})
export class SingleSelectComponent implements OnInit {
	@Input() singleOptions: any;
	@Input() singleFilter: any;
	@Input() placeholder: string = 'Default Placeholder';

	@Output() filtersChanged = new EventEmitter<number>();

	public selectedSingleOptions: KeyValuePair[];

	ngOnInit(): void {
		// set the first filter value as default value
		this.selectedSingleOptions = this.singleOptions[0].id;
	}

	/* Indicator filter change send value to parent filter-container component  */
	onSingleFilterChanged(event) {
		this.filtersChanged.emit(event.value);
	}
}
