import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { authGuard } from '../../core/guards/auth.guard';

import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardsComponent } from './dashboards/dashboards.component';

const routes: Routes = [
    {
        path: '',
        component: DashboardsComponent,
        canActivate: [authGuard],
    },
    {
        path: ':typeInd/:customDashboardId',
        component: DashboardComponent,
        canActivate: [authGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DashboardsV2RoutingModule {}
