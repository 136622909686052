import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    BaseFilterDefinition,
    DashboardDefinitionDTO,
    DashboardFilterFormInd,
    keyValueDto,
    organisationDataSetChange,
    organisationDataSetsDTO,
} from 'src/app/core/services/dashboardsv2.service';

@Component({
    selector: 'app-filter-container',
    templateUrl: './filter-container.component.html',
    styleUrls: ['./filter-container.component.scss'],
})
export class FilterContainerComponent implements OnInit {
    selectedOrganisation: organisationDataSetsDTO;
    selectedOrganisationDataSet: keyValueDto;

    @Input() config: DashboardDefinitionDTO;
    @Output() filtersChanged = new EventEmitter();
    @Output() orgDataSetChanged = new EventEmitter<organisationDataSetChange>();

    get DashboardFilterFormInd(): typeof DashboardFilterFormInd {
        return DashboardFilterFormInd;
    }

    ngOnInit(): void {
        this.selectedOrganisation = this.config.organisationDataSets[0];
        this.selectedOrganisationDataSet = this.selectedOrganisation.dataSets[0];

        // set default first value and load data for Indicator filter
        let singleFilterSelect = this.config.filters.filter((m) => m.allowMultiSelect == false && m.selection.length !== 1);
        singleFilterSelect.forEach((a) => this.onSingleSelection(a, a.options[0].id));

        const el = document.querySelector('.filters');
        const observer = new IntersectionObserver(
            ([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1),
            { threshold: [1] }
        );

        observer.observe(el);
    }

    /** Event fired when a filter has been updated */
    onFilterChanged(filter: BaseFilterDefinition, selection: number[]) {
        if (JSON.stringify(selection) !== JSON.stringify(filter.selection)) {
            filter.selection = selection;
            this.createAndEmitDataSetRequest();
        }
    }

    /** Create and emit the current indicator filter state to the dashboard */
    onSingleSelection(filter: BaseFilterDefinition, emitVal: any) {
        let selection: Array<number>;
        selection = [emitVal];

        if (JSON.stringify(selection) !== JSON.stringify(filter.selection)) {
            filter.selection = selection;
            this.createAndEmitDataSetRequest();
        }
    }

    /** Create and emit the current filter state to the dashboard */
    createAndEmitDataSetRequest() {
        this.filtersChanged.emit();
    }

    /** Create and emit the changed org / data set */
    createOrganisationChangeRequest() {
        this.selectedOrganisationDataSet = this.selectedOrganisation.dataSets[0];
        this.createOrganisationDataSetChangeRequest();
    }

    /** Create and emit the changed org / data set */
    createOrganisationDataSetChangeRequest() {
        this.orgDataSetChanged.emit({
            organisationId: this.selectedOrganisation.id,
            dataSetId: this.selectedOrganisationDataSet.id,
        });
    }
}
