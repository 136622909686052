import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MessageService } from '../../services/message.service';
import { NavigationItem } from '../../../shared/models/UserUI';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

declare let gtag;

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
    title = 'SportEyeFE';
    opened: boolean = false;
    closed: boolean = false;
    isLoggedIn: boolean = false;
    menuItems: NavigationItem[] = [];

    constructor(
        private messageService: MessageService,
        private authService: AuthService,
        public router: Router,
        public titleService: Title,
        @Inject(DOCUMENT) private document: Document
    ) {
        if (this.authService.isUserLoggedIn()) {
            this.isLoggedIn = true;
        }

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                gtag('config', 'G-9XXLXT84GK', {
                    page_path: event.urlAfterRedirects,
                });
            }
        });

        this.addTheme();
    }

    /** NgOnInit Event */
    ngOnInit() {
        this.messageService.sideNavToggle$.subscribe(() => {
            this.menuItems = this.menuItems.length == 0 ? this.authService.getTopMenu() : this.menuItems;
            this.toggleSideNav();
        });

        this.messageService.isLoggedIn$.subscribe((loggedIn) => {
            this.isLoggedIn = loggedIn;
        });

        this.messageService.closeNavToggle$.subscribe(() => {
            this.opened = false;
        });
    }

    /** Add Theming Support */
    addTheme() {
        this.titleService.setTitle('ActiveXchange');
        let themeCssFilename = 'axcanada.css';

        switch (window.location.hostname.toLowerCase()) {
            case 'aqeye.sportseye.app':
            case 'aqeye.activexchange.app':
            case 'aqeye.activexchange.org':
                themeCssFilename = 'aqeye.css';
                this.titleService.setTitle('AQEye (powered by ActiveXchange)');
                break;

            case 'cpra.activexchange.ca':
                this.titleService.setTitle('CPRA (powered by ActiveXchange)');
                themeCssFilename = 'cpra.css';
                break;
        }

        const headEl = this.document.getElementsByTagName('head')[0];
        const linkEl = this.document.createElement('link');
        linkEl.rel = 'stylesheet';
        linkEl.href = themeCssFilename;

        headEl.appendChild(linkEl);
    }

    /** Toggle Side Navigation */
    toggleSideNav() {
        if (this.isLoggedIn) this.opened = !this.opened;
    }
}
