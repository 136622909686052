import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	QueryList,
	SimpleChanges,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import {
	DashboardsServiceV2,
	WidgetDataDTO,
	WidgetDataSetRequestDTO,
	WidgetDefinitionDTO,
} from 'src/app/core/services/dashboardsv2.service';

@Component({
	selector: 'app-widget-stats-table',
	templateUrl: './widget-stats-table.component.html',
	styleUrls: ['./widget-stats-table.component.scss'],
})
export class WidgetStatsTableComponent implements OnChanges, AfterViewInit {
	@Input() widgetConfig: WidgetDefinitionDTO;
	@Input() dataSetRequest: WidgetDataSetRequestDTO;
	@Input() loadWidgetData: boolean;

	@ViewChild('statsTable') statsTable: ElementRef<HTMLElement>;
	@ViewChildren('autoSize') autoSizeElements!: QueryList<ElementRef<HTMLElement>>;
	@HostListener('window:resize') onResize() {
		this.autoSizeText();
	}

	widgetData: WidgetDataDTO;
	defaultColourSet: string[] = ['#735BA7', '#60C360', '#F47878', '#489292', '#F4B078'];

	subscription: Subscription;
	isLoaded: boolean;

	constructor(private dashboardService: DashboardsServiceV2, private sanitizer: DomSanitizer) {}

	ngAfterViewInit(): void {
		const observer = new MutationObserver(() => {
			this.autoSizeText();
		});
		observer.observe(this.statsTable.nativeElement, { childList: true });
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.dataSetRequest && !changes.dataSetRequest.isFirstChange()) {
			if (this.loadWidgetData) {
				this.loadData();
			} else {
				this.isLoaded = false;
			}
		}

		if (changes.loadWidgetData && !this.isLoaded) {
			if (changes.loadWidgetData.currentValue) {
				this.loadData();
			}
			//stop loading unfinished widget
			if (!changes.loadWidgetData.currentValue && this.subscription) {
				this.subscription.unsubscribe();
			}
		}
	}

	private loadData(): void {
		this.widgetConfig.loading = true;
		this.isLoaded = false;

		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.dashboardService
			.getDataForWidget(this.widgetConfig.dataEndpoint, this.dataSetRequest)
			.subscribe((data: WidgetDataDTO) => {
				this.widgetData = data;
				this.widgetConfig.loading = false;
				this.isLoaded = true;
			});
	}

	formatString(label: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(label.replace('\n', '<br/>'));
	}

	private autoSizeText(): void {
		if (this.autoSizeElements.length === 0) {
			return;
		}

		const elements = this.autoSizeElements.map((element: ElementRef<HTMLElement>) => element.nativeElement);

		elements.forEach((element: HTMLElement) => (element.style.transform = 'scale(1)'));
		const maxWidth = Math.max(...elements.map((element: HTMLElement) => element.offsetWidth));
		const maxHeight = Math.max(...elements.map((element: HTMLElement) => element.offsetHeight));
		elements.forEach((element: HTMLElement) => {
			const heightScale = element.parentElement.offsetHeight / maxHeight;
			const widthScale = element.parentElement.offsetWidth / maxWidth;

			element.style.transform = `scale(${Math.min(heightScale, widthScale, 5)})`;
		});
	}
}
