<div class="container-fluid container-fluid--sePadding container-dashboardHomepage">
    <app-page-heading [iconName]="'account_circle'" [title]="greetingTitle" [subTitle]="greetingSubTitle">
        <app-icon-button
            class="d-none d-sm-block"
            *ngIf="showUploadData"
            (buttonClicked)="uploadData()"
            [icon]="'backup'"
            [text]="'UPLOAD DATA'"
            [size]="'large'"></app-icon-button>
    </app-page-heading>

    <!-- Navigation Tiles -->
    <section class="mainNavTiles">
        <div class="sectionHeader brand-color-dark-grey">QUICK ACCESS TO MODULES</div>

        <!-- DYNAMIC TILES -->
        <div class="flexGrid">
            <a *ngFor="let navItem of navTiles" [routerLink]="navItem.routeKey">
                <div class="image"><img [src]="'/assets/images/home/' + navItem.icon + '.jpg'" /></div>
                <h3>{{ navItem.text }}</h3>
                <p>{{ navItem.description }}</p>
            </a>
        </div>
    </section>

    <br />

    <!-- Data Insights -->
    <section class="section-break" *ngIf="dataIssues">
        <div class="sectionHeader">DATA INSIGHTS</div>

        <div class="row">
            <!-- Last Data Uploaded -->
            <div class="col-12 col-sm-6 col-lg-3" *ngIf="dataIssues?.createdDateTimeUTC">
                <div class="navigationTile-small d-flex align-items-center">
                    <mat-icon class="material-icons icon mb-2" style="color: green">date_range</mat-icon>
                    <div class="ms-2">
                        <small class="ms-1 brand-color-dark-grey">Last Data Received</small>
                        <div class="mb-1 text-success" style="font-size: 1rem">
                            <b>{{ dataIssues.createdDateTimeUTC | date : 'd MMM yyyy' }}</b>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Data Issues Tile -->
            <div class="col-12 col-sm-6 col-lg-3" *ngIf="dataIssues?.totalIssues > 0">
                <div
                    [routerLink]="['/job-errors/' + dataIssues.jobId]"
                    class="navigationTile-small-red d-flex align-items-center">
                    <mat-icon class="material-icons-outlined icon mb-2 text-danger">report</mat-icon>
                    <div class="ms-2">
                        <small class="ms-1 brand-color-dark-grey">Last import issues</small>
                        <div class="mb-1 text-danger" style="color: green; font-size: 1rem">
                            {{ dataIssues.totalIssues }} unresolved issues
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
