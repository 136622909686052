import { Component, OnInit } from '@angular/core';
import { DashboardsDTO, DashboardsServiceV2 } from '../../../core/services/dashboardsv2.service';

@Component({
	selector: 'app-dashboardsv2',
	templateUrl: './dashboards.component.html',
	styleUrls: ['./dashboards.component.scss'],
	providers: [DashboardsServiceV2]
})
export class DashboardsComponent implements OnInit {

	pageData: DashboardsDTO;

	constructor(private dashboardsService: DashboardsServiceV2) {
	}

	ngOnInit(): void {
		this.dashboardsService.getAvailableDashboardsForUser().subscribe(data => {
			this.pageData = data;
			this.pageData.dashboardSections.forEach(x => {
				x.dashboards.sort((a, b) => a.order - b.order);
			})
		});
	}
}
