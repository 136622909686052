import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { forkJoin, Subscription } from 'rxjs';
import { debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import BaseComponent from 'src/app/core/base/base-component.component';
import { AuthService } from 'src/app/core/services/auth.service';
import {
    DashboardFilterRequestDTO,
    DashboardsServiceV2,
    WidgetContainerDTO,
    WidgetDefinitionDTO,
    WidgetFilterDto,
} from 'src/app/core/services/dashboardsv2.service';
import { OrganisationService } from 'src/app/core/services/organisation.service';
import {
    ConfirmationComponent,
    ConfirmDialogModel,
} from 'src/app/shared/components/confirmation/confirmation.component';
import { DashboardViewDto } from 'src/app/shared/models/dashboards/dashboard-view-dto.model';
import { DashboardViewFilterDto } from 'src/app/shared/models/dashboards/dashboard-view-filter-dto.model';
import { DashboardViewWidgetContainerDto } from 'src/app/shared/models/dashboards/dashboard-view-widget-container-dto.model';
import { DashboardViewWidgetDto } from 'src/app/shared/models/dashboards/dashboard-view-widget-dto.model';
import { SharingLevel } from 'src/app/shared/models/movement';
import { UserGroupDto } from 'src/app/shared/models/organisation';

@Component({
    selector: 'app-dashboard-view-modal',
    templateUrl: './dashboard-view-modal.component.html',
    styleUrl: './dashboard-view-modal.component.scss',
    providers: [DashboardsServiceV2],
})
export class DashboardViewModalComponent extends BaseComponent implements OnInit {
    private userGroupsSelectSubscription: Subscription;

    @ViewChildren('userGroupsSelect') userGroupsSelect: QueryList<MatSelect>;

    form: FormGroup;
    loading: boolean;
    userGroups: UserGroupDto[];
    view: DashboardViewDto;
    filters: DashboardViewFilterDto[];
    containers: DashboardViewWidgetContainerDto[];
    widgets: DashboardViewWidgetDto[];
    existingViews: DashboardViewDto[];
    dashboardTypeInd: number;
    isSaving: boolean;
    isEdit: boolean;
    canEdit: boolean;

    get sharingLevel(): typeof SharingLevel {
        return SharingLevel;
    }

    get selectedSharingLevel(): SharingLevel {
        return this.form.get('sharingLevel').value;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA)
        data: {
            view: DashboardViewDto;
            filters: DashboardFilterRequestDTO[];
            containers: WidgetContainerDTO[];
            widgets: WidgetDefinitionDTO[];
            isEdit: boolean;
            canEdit: boolean;
        },
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private organisationService: OrganisationService,
        private dashboardService: DashboardsServiceV2,
        private matDialogRef: MatDialogRef<DashboardViewModalComponent>,
        private dialog: MatDialog
    ) {
        super();
        this.view = data.view;
        this.isEdit = data.isEdit;
        this.canEdit = data.canEdit;
        this.filters = data.filters.map((filter: DashboardFilterRequestDTO) => ({
            dashboardFilterTypeInd: filter.filterTypeInd,
            filterId: filter.filterId,
            filterSelection: filter.filterValues,
        }));
        this.containers = data.containers.map((container: WidgetContainerDTO) => ({
            widgetContainerId: container.id,
            isCollapsed: container.isCollapsed,
        }));
        data.widgets
            .filter((widget) => widget.widgetFilters)
            .forEach((widget: WidgetDefinitionDTO) =>
                widget.widgetFilters.forEach((widgetFilter: WidgetFilterDto) =>
                    this.filters.push({
                        filterId: widgetFilter.filterId,
                        filterSelection: [widgetFilter.filterSelection],
                        widgetId: widget.id,
                    })
                )
            );
        this.widgets = data.widgets
            .filter((widget) => widget.sortFieldIndex != null || widget.legendSelection != null || widget.tableFilter != null)
            .map((widget: WidgetDefinitionDTO) => ({
                widgetId: widget.id,
                sortFieldIndex: widget.sortFieldIndex,
                sortFieldAscending: widget.sortFieldAscending,
                legendSelection: widget.legendSelection,
                tableFilter: widget.tableFilter,
            }));
    }

    ngOnInit(): void {
        this.loadData();
    }

    save(): void {
        if (this.form.valid) {
            if (this.form.get('dashboardView').value > 0 && !this.isEdit) {
                const viewName = this.form.get('name').value;
                const dialogData = new ConfirmDialogModel(
                    'Confirm Overwrite',
                    `Are you sure you want to overwrite ${viewName} View with current settings? This CANNOT be undone`,
                    'CONFIRM',
                    'green'
                );

                const dialogRef = this.dialog.open(ConfirmationComponent, {
                    maxWidth: '400px',
                    data: dialogData,
                    panelClass: 'seDialog',
                });

                dialogRef
                    .afterClosed()
                    .pipe(
                        filter((result: boolean) => result),
                        tap(() => this.updateView()),
                        switchMap(() => this.dashboardService.saveDashboardView(this.view)),
                        takeUntil(this.subscriptionComplete)
                    )
                    .subscribe({
                        next: () => {
                            this.matDialogRef.close(true);
                            this.isSaving = false;
                        },
                        error: () => {
                            this.isSaving = false;
                        },
                    });
            } else {
                this.updateView();

                this.dashboardService.saveDashboardView(this.view).subscribe({
                    next: () => {
                        this.matDialogRef.close(true);
                        this.isSaving = false;
                    },
                    error: () => {
                        this.isSaving = false;
                    },
                });
            }
        }
    }

    updateView(): void {
        this.isSaving = true;
        this.view.name = this.form.get('name').value;
        this.view.isDefaultView = this.form.get('isDefault').value;
        this.view.sharingLevel = this.selectedSharingLevel;
        this.view.isReadOnly = this.form.get('isReadOnly').value;
        this.view.userGroups =
            this.selectedSharingLevel == SharingLevel.UserGroups ? this.form.get('userGroups').value : [];
        this.view.filters = this.filters;
        this.view.containers = this.containers;
        this.view.widgets = this.widgets;
    }

    delete(): void {
        if (this.view.id > 0) {
            const dialogData = new ConfirmDialogModel(
                'Confirm Delete',
                'Are you sure you want to delete this Dashboard View?',
                'DELETE'
            );

            const dialogRef = this.dialog.open(ConfirmationComponent, {
                maxWidth: '400px',
                data: dialogData,
                panelClass: 'seDialog',
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((result: boolean) => result),
                    switchMap(() => this.dashboardService.deleteDashboardView(this.view.id)),
                    takeUntil(this.subscriptionComplete)
                )
                .subscribe({
                    next: () => {
                        this.matDialogRef.close(true);
                        this.isSaving = false;
                    },
                    error: () => {
                        this.isSaving = false;
                    },
                });
        }
    }

    handleSharingChange(): void {
        const readOnlyControl = this.form.get('isReadOnly');
        const userGroupsControl = this.form.get('userGroups');
        switch (this.selectedSharingLevel) {
            case SharingLevel.Organisation: {
                readOnlyControl.enable();
                userGroupsControl.clearValidators();
                userGroupsControl.markAsUntouched();
                break;
            }
            case SharingLevel.Private: {
                readOnlyControl.setValue(false);
                readOnlyControl.disable();
                userGroupsControl.clearValidators();
                userGroupsControl.markAsUntouched();
                break;
            }
            case SharingLevel.UserGroups: {
                readOnlyControl.enable();
                userGroupsControl.setValidators([Validators.required]);
                this.subscribeToUserGroupSelectChanges();
            }
        }
        userGroupsControl.updateValueAndValidity();
    }

    handleViewChange(): void {
        const selectedViewId: number = this.form.get('dashboardView').value;
        if (selectedViewId == 0) {
            this.view = {
                id: 0,
                name: '',
                dashboardTypeInd: this.dashboardTypeInd,
                isDefaultView: false,
                sharingLevel: SharingLevel.Private,
                isCreator: true,
                isReadOnly: false,
                userGroups: [],
                filters: [],
                containers: [],
                widgets: [],
            };
        } else {
            this.view = this.existingViews.find((view) => view.id == selectedViewId);
        }

        this.loadForm();
        this.form.markAsDirty();
    }

    private loadData(): void {
        this.loading = true;
        this.dashboardTypeInd = this.view.dashboardTypeInd;
        const organisationId = this.authService.getUserOrganisationId();

        forkJoin({
            userGroups: this.organisationService.getOrganisationUserGroups(organisationId),
            views: this.dashboardService.getDashboardViews(this.dashboardTypeInd),
        }).subscribe((data: { userGroups: UserGroupDto[]; views: DashboardViewDto[] }) => {
            this.userGroups = data.userGroups;
            this.existingViews = data.views;
            this.loadForm();

            this.loading = false;
        });
    }

    private loadForm(): void {
        this.form = this.formBuilder.group({
            dashboardView: [{ value: this.view.id, disabled: !this.canEdit }, Validators.required],
            name: [{ value: this.view.name, disabled: !this.canEdit }, Validators.required],
            isDefault: [this.view.isDefaultView],
            sharingLevel: [{ value: this.view.sharingLevel, disabled: !this.canEdit }, Validators.required],
            isReadOnly: [
                {
                    value: this.view.isReadOnly,
                    disabled: !this.canEdit || this.view.sharingLevel == this.sharingLevel.Private,
                },
            ],
            userGroups: [{ value: null, disabled: !this.canEdit }],
        });

        const selectedUserGroups = this.userGroups.filter((x) => this.view.userGroups.some((y) => y.id == x.id));
        const userGroupsControl = this.form.get('userGroups');
        userGroupsControl.setValue(selectedUserGroups);
        if (this.selectedSharingLevel == SharingLevel.UserGroups) {
            userGroupsControl.setValidators([Validators.required]);
        }
    }

    private subscribeToUserGroupSelectChanges(): void {
        if (!this.userGroupsSelectSubscription) {
            this.userGroupsSelectSubscription = this.userGroupsSelect.changes.pipe(debounceTime(50)).subscribe(() => {
                if (this.userGroupsSelect.length > 0) {
                    this.userGroupsSelect.first.focus();
                }
            });
        }
    }
}
