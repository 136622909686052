import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-singleselect-container',
	templateUrl: 'filter-singleselect.component.html',
	styleUrls: ['./filter-singleselect.component.scss'],
})
export class SingleSelectComponent implements OnInit, OnChanges {
	@Input() singleOptions: any;
	@Input() singleFilter: any;
    @Input() selection: number;
	@Input() placeholder: string = 'Default Placeholder';

	@Output() filtersChanged = new EventEmitter<number>();

	public selectedSingleOptions: number;

	ngOnInit(): void {
		// set the first filter value as default value
		this.selectedSingleOptions = this.selection;
	}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selection && changes.selection.firstChange == false) {
            this.selectedSingleOptions = changes.selection.currentValue;
        }
    }

	/* Indicator filter change send value to parent filter-container component  */
	onSingleFilterChanged(event) {
		this.filtersChanged.emit(event.value);
	}
}
