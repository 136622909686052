import { AfterViewChecked, Component, ElementRef, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { EChartsOption } from 'echarts';
import {
	DashboardsServiceV2,
	WidgetDataDTO,
	WidgetDataSetRequestDTO,
	WidgetDefinitionDTO,
    WidgetFilterDto,
} from 'src/app/core/services/dashboardsv2.service';

@Component({
	selector: 'app-widgetheatmap-graph',
	templateUrl: './widget-heatmap.component.html',
	styleUrls: ['./widget-heatmap.component.scss'],
})
export class WidgetHeatmapComponent implements OnInit, OnChanges, AfterViewChecked {
	@Input() widgetConfig: WidgetDefinitionDTO;
	@Input() dataSetRequest: WidgetDataSetRequestDTO;
	@Input() loadWidgetData: boolean;

    @ViewChildren('sidePanelButtonText', {read: ElementRef}) buttonTextElements: QueryList<ElementRef>;

	echartOptions: EChartsOption;
	dashboardService: DashboardsServiceV2;
	widgetData: WidgetDataDTO;
	subscription: any;
	isLoaded: boolean;
    hasFilters: boolean;

	constructor(dashboardService: DashboardsServiceV2) {
		this.dashboardService = dashboardService;
	}

    ngOnInit(): void {
        if (this.widgetConfig.widgetFilters && this.widgetConfig.widgetFilters.length > 0) {
			this.hasFilters = true;
		}

        this.loadData();
    }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.dataSetRequest && !changes.dataSetRequest.isFirstChange()) {
			if (this.loadWidgetData) {
				this.loadData();
			} else {
				this.isLoaded = false;
			}
		}

		if (changes.loadWidgetData && !this.isLoaded && !changes.loadWidgetData.isFirstChange()) {
			if (changes.loadWidgetData.currentValue) {
				this.loadData();
			}
			//stop loading unfinished widget
			if (!changes.loadWidgetData.currentValue && this.subscription) {
				this.subscription.unsubscribe();
			}
		}
	}

    ngAfterViewChecked(): void {
		if (this.buttonTextElements.some((element: ElementRef) => {
			const parentStyle = getComputedStyle(element.nativeElement.parentElement);
			const parentWidth = element.nativeElement.parentElement.offsetWidth - parseFloat(parentStyle.paddingLeft) - parseFloat(parentStyle.paddingRight);
			return element.nativeElement.offsetWidth > parentWidth;
		})) {
			this.buttonTextElements.forEach((element: ElementRef) => {
				element.nativeElement.style.display = 'block';
				element.nativeElement.style.transform = 'rotate(270deg)';
			});
		}
	}

	loadData() {
		this.widgetConfig.loading = true;
		this.isLoaded = false;

        let request: WidgetDataSetRequestDTO = JSON.parse(JSON.stringify(this.dataSetRequest));

        if (this.hasFilters) {
			this.widgetConfig.widgetFilters.forEach((widgetFilter: WidgetFilterDto) => {
				request.filters.push({
					filterTypeInd: 0,
					filterId: widgetFilter.filterId,
					filterValues: [widgetFilter.filterSelection],
				});
			});
		}

		if (this.subscription) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.dashboardService
			.getDataForWidget(this.widgetConfig.dataEndpoint, request)
			.subscribe((response: WidgetDataDTO) => {
				this.widgetData = response;
				this.widgetConfig.loading = false;
				this.isLoaded = true;
				if (response.data) {
					this.getHeatMapData();
				}
			});
	}

	/** Create data for HeadMap graph */
	getHeatMapData() {
		// get list of xAxis data (hours)
		let xAxis = this.widgetData.dimensions.map((a) => a.name);

		// get data in [time-day-value] format else set zero
		const data = this.widgetData.data.map(function (item) {
			return [item[0], item[2], item[3] || 0];
		});

		// get list of yAxis data (weekDays)
		const yAxisArr = this.widgetData.data.map((a) => a[4]);

		// Select all distinct xAxis data(days)
		const yAxis = yAxisArr.filter((n, i) => yAxisArr.indexOf(n) === i);

		let maxValue, minValue;

		let yAxisLabel = this.widgetData.axis[0].axisLabel;
		let xAxisLabel = this.widgetData.axis[1].axisLabel;

		// get all values from the data collection
		let dataValueList = data.map((a) => a[2]);

		// get max value from the data collection
		maxValue = dataValueList.reduce((op, item) => (op = op > item ? op : item), 0);
		// get min value from the data collection
		minValue = dataValueList.reduce((op, item) => (op = op < item ? op : item), 0);

		let colourRange = this.widgetData.dimensions[0].colours;

		// set eChart properties
		this.echartOptions = {
			tooltip: {
				position: 'top',
			},
			grid: {
				height: '80%',
				top: '10%',
			},
			xAxis: {
				type: 'category',
				data: xAxis,
				name: xAxisLabel,
				nameLocation: 'middle',
				nameGap: 30,
				splitArea: {
					show: true,
				},
				position: 'top',
				axisLine: {
					show: true,
				},
			},

			yAxis: {
				type: 'category',
				data: yAxis,
				axisLabel: {
					show: true,
					interval: 0,
				},
				name: yAxisLabel,
				nameLocation: 'middle',
				nameGap: 30,
				splitArea: {
					show: true,
				},
				inverse: this.widgetData.axis[0].inverse,
			},
			visualMap: {
				min: minValue, // define min value for color dsiplay
				max: maxValue, // define max value for color display
				show: false,
				inRange: {
					color: colourRange,
				},
			},
			series: [
				{
					type: 'heatmap',
					data: data,
					label: {
						show: true,
					},
					emphasis: {
						itemStyle: {
							shadowBlur: 10,
							shadowColor: 'rgba(0, 0, 0, 0.5)',
						},
					},
				},
			],
		};
	}

    updateWidgetFilter(filterId: string, filterKey: number) {
		const widgetFilter = this.widgetConfig.widgetFilters.find((filter) => filter.filterId === filterId);
		if (widgetFilter.filterSelection != filterKey) {
			widgetFilter.filterSelection = filterKey;
			this.loadData();
		}
	}
}
