<div id="pageHeader">
    <app-icon-button class="screenOnly" (buttonClicked)="print()" icon="print" text="Export / Print"> </app-icon-button>
</div>

<div id="pageFooter"></div>

<div id="content">
    <div class="pageContainer" *ngFor="let widget of export.widgets; let i = index">
        <div class="widgetHeaderContainer">
            <img class="widgetHeaderImage" src="assets/images/ActivexChangeLogoWhite.png" />
            <div class="widgetHeaderText">
                <div class="widgetHeaderIcon">
                    <mat-icon>insert_chart</mat-icon>
                </div>
                <div class="widgetHeaderTitle">
                    <h1>{{ export.dashboardTitle }}</h1>
                    <h2>{{ export.dashboardSubtitle }}</h2>
                </div>
            </div>
        </div>

        <div class="widgetContainer" [ngSwitch]="widget.widget.widgetTypeInd">
            <div class="widgetTitle">{{ widget.widget.title }}</div>
            <div class="widgetSubtitle">{{ widget.widget.subTitle }}</div>
            <div [ngSwitch]="widget.widget.widgetTypeInd">
                <app-widget-graph
                    *ngSwitchCase="0"
                    [widgetConfig]="widget.widget"
                    [dataSetRequest]="export.dataSetRequest"
                    [loadWidgetData]="true">
                </app-widget-graph>

                <app-widget-stats
                    *ngSwitchCase="1"
                    [dataSetRequest]="export.dataSetRequest"
                    [widgetConfig]="widget.widget"
                    [loadWidgetData]="true">
                </app-widget-stats>

                <app-widget-table
                    *ngSwitchCase="2"
                    [dataSetRequest]="export.dataSetRequest"
                    [widgetConfig]="widget.widget"
                    [loadWidgetData]="true">
                </app-widget-table>

                <div *ngSwitchCase="3" class="ratio ratio-16x9">
                    <iframe [src]="widget.widget.dataEndpoint | safeUrl"></iframe>
                </div>

                <app-widgetheatmap-graph
                    *ngSwitchCase="4"
                    [dataSetRequest]="export.dataSetRequest"
                    [widgetConfig]="widget.widget"
                    [loadWidgetData]="true">
                </app-widgetheatmap-graph>

                <app-widget-graph
                    *ngSwitchCase="5"
                    [dataSetRequest]="export.dataSetRequest"
                    [widgetConfig]="widget.widget"
                    [loadWidgetData]="true">
                </app-widget-graph>

                <app-stacked-graph
                    *ngSwitchCase="6"
                    [dataSetRequest]="export.dataSetRequest"
                    [widgetConfig]="widget.widget"
                    [loadWidgetData]="true">
                </app-stacked-graph>

                <app-widget-scatter-graph
                    *ngSwitchCase="7"
                    [dataSetRequest]="export.dataSetRequest"
                    [widgetConfig]="widget.widget"
                    [loadWidgetData]="true">
                </app-widget-scatter-graph>

                <app-widget-stats-table
                    *ngSwitchCase="8"
                    [dataSetRequest]="export.dataSetRequest"
                    [widgetConfig]="widget.widget"
                    [loadWidgetData]="true">
                </app-widget-stats-table>

                <div [hidden]="!widget.widget.loading" class="loadingOverlay">
                    <img src="/assets/images/dashboard-loader.gif" />
                </div>
            </div>
        </div>

        <div class="widgetFooterContainer">Page {{ i + 1 }}</div>

        <hr class="screenOnly" />
    </div>

    <div class="pageContainer">
        <div class="widgetHeaderContainer">
            <img class="widgetHeaderImage" src="assets/images/ActivexChangeLogoWhite.png" />
            <div class="widgetHeaderText">
                <div class="widgetHeaderIcon">
                    <mat-icon>insert_chart</mat-icon>
                </div>
                <div class="widgetHeaderTitle">
                    <h1>{{ export.dashboardTitle }}</h1>
                    <h2>{{ export.dashboardSubtitle }}</h2>
                </div>
            </div>
        </div>

        <div class="widgetContainer">filters here</div>

        <div class="widgetFooterContainer">Page {{ export.widgets.length + 1 }}</div>
    </div>
</div>
