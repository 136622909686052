import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import BaseComponent from 'src/app/core/base/base-component.component';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { MessageService } from 'src/app/core/services/message.service';
import { MovementService } from 'src/app/core/services/movement/movement.service';
import { UserService } from 'src/app/core/services/user.service';
import { CheckboxState } from 'src/app/shared/models/checkboxState.model';
import { MovementGroupDto, MovementMonitoringItemDto, MovementMonitoringType } from 'src/app/shared/models/movement';
import { MovementEmailSubscriptionDto } from 'src/app/shared/models/user/movementEmailSubscriptionDto.model';

@Component({
    selector: 'app-email-subscriptions',
    templateUrl: './email-subscriptions.component.html',
    styleUrls: ['./email-subscriptions.component.scss'],
})
export class EmailSubscriptionsComponent extends BaseComponent implements OnInit {
    @Output() showErrors = new EventEmitter<string[]>();

    form: FormGroup;
    saving: boolean = false;

    movementMonitoringItems: MovementMonitoringItemDto[];
    movementEmailSubscription: MovementEmailSubscriptionDto;
    additionalRecipients: { name: string }[] = [];

    emailControl = new FormControl('');

    groupCheckboxStates: CheckboxState[];

    get isActiveControl(): AbstractControl<boolean> {
        return this.form.get('isActive');
    }

    get itemSelectionsControl(): AbstractControl<MovementMonitoringItemDto[]> {
        return this.form.get('itemSelections');
    }

    get additionalRecipientsControl(): AbstractControl<string[]> {
        return this.form.get('additionalRecipients');
    }

    constructor(
        private formBuilder: FormBuilder,
        private movementService: MovementService,
        private userService: UserService,
        private messageService: MessageService,
        private errorHandlerService: ErrorHandlerService
    ) {
        super();
    }

    ngOnInit(): void {
        this.loading = true;

        forkJoin([this.movementService.getMovementMonitoring(), this.userService.getMovementEmailSubscription()])
            .pipe(takeUntil(this.subscriptionComplete))
            .pipe(finalize(() => (this.loading = false)))
            .subscribe(([items, subscription]: [MovementMonitoringItemDto[], MovementEmailSubscriptionDto]) => {
                this.movementMonitoringItems = items;

                const groups = this.movementMonitoringItems
                    .map((item) => item.group)
                    .filter((value, index, array) => array.findIndex((x) => x?.id === value?.id) === index)
                    .sort((a, b) => {
                        const aName = a?.name.toLowerCase();
                        const bName = b?.name.toLowerCase();
                        if (aName === bName) {
                            return 0;
                        }
                        if (!aName) {
                            return -1;
                        }
                        if (!bName) {
                            return 1;
                        }
                        return aName < bName ? -1 : 1;
                    });

                this.movementEmailSubscription = subscription;
                this.additionalRecipients = subscription.additionalRecipients.map((x) => {
                    return { name: x };
                });

                const itemSelections = this.movementMonitoringItems.filter(
                    (x) =>
                        subscription.quadkeyCollectionIds.some((y) => y === x.id) ||
                        subscription.monitoredNidSiteIds.some((y) => y === x.nidSiteMovementMonitoringId) ||
                        subscription.groupIds.some((y) => y === x.group?.id)
                );

                this.form = this.formBuilder.group({
                    isActive: [subscription.isActive],
                    itemSelections: [itemSelections, subscription.isActive ? Validators.required : null],
                    additionalRecipients: [this.additionalRecipients],
                });

                this.groupCheckboxStates = groups.map((group: MovementGroupDto) => {
                    return { id: group?.id, name: group?.name, isChecked: false, isIndeterminate: false };
                });

                this.setGroupCheckboxStates();
            });
    }

    movementSubscriptionChange(checked: boolean) {
        if (checked) {
            this.itemSelectionsControl.addValidators(Validators.required);
        } else {
            this.itemSelectionsControl.removeValidators(Validators.required);
        }
        this.itemSelectionsControl.updateValueAndValidity();
    }

    additionalRecipientsChanged(): void {
        this.additionalRecipientsControl.markAsDirty();
        this.additionalRecipientsControl.updateValueAndValidity();
    }

    onSubmit(): void {
        this.showErrors.emit([]);
        this.saving = true;

        const groupIds = this.groupCheckboxStates.filter((x) => x.isChecked && x.id).map((x) => x.id);

        this.movementEmailSubscription = {
            ...this.movementEmailSubscription,
            isActive: this.isActiveControl.value,
            quadkeyCollectionIds: this.itemSelectionsControl.value
                .filter(
                    (x) =>
                        x.type === MovementMonitoringType.QuadkeyCollection &&
                        (!x.group || !groupIds.includes(x.group.id))
                )
                .map((x) => x.id),
            monitoredNidSiteIds: this.itemSelectionsControl.value
                .filter(
                    (x) =>
                        x.type === MovementMonitoringType.MonitoredNidSite &&
                        (!x.group || !groupIds.includes(x.group.id))
                )
                .map((x) => x.nidSiteMovementMonitoringId),
            groupIds: groupIds,
            additionalRecipients: this.additionalRecipients.map((x) => x.name),
        };

        this.userService
            .updateMovementEmailSubscription(this.movementEmailSubscription)
            .pipe(takeUntil(this.subscriptionComplete))
            .pipe(finalize(() => (this.saving = false)))
            .subscribe(() => {
                this.messageService.showSuccess('Your email subscription settings have been saved.');
                this.form.markAsPristine();
                this.form.markAsUntouched();
            });
    }

    setGroupCheckboxStates(): void {
        this.groupCheckboxStates.forEach((state) => {
            var groupLength = this.movementMonitoringItems.filter((x) => x.group?.id === state.id).length;
            var selectedLength = this.itemSelectionsControl.value.filter((x) => x.group?.id === state.id).length;
            state.isChecked = selectedLength > 0 && groupLength === selectedLength;
            state.isIndeterminate = selectedLength > 0 && groupLength > selectedLength;
        });
    }

    getGroupState(group: MovementGroupDto): CheckboxState {
        return this.groupCheckboxStates.find((x) => x?.id === group?.id);
    }

    toggleSelectAllGroup(checked: boolean, groupState: CheckboxState): void {
        const groupItems = this.movementMonitoringItems.filter((item) => item.group?.id === groupState.id);
        const newValue = [...this.itemSelectionsControl.value];
        if (checked) {
            groupItems
                .filter((item) => !newValue.some((x) => x.id === item.id))
                .forEach((item) => {
                    newValue.push(item);
                });
            groupState.isChecked = true;
        } else {
            groupItems.forEach((item) => {
                const index = newValue.findIndex((y) => y.id === item.id);
                if (index >= 0) {
                    newValue.splice(index, 1);
                }
            });
            groupState.isChecked = false;
        }
        groupState.isIndeterminate = false;
        this.itemSelectionsControl.patchValue(newValue);
        this.itemSelectionsControl.markAsDirty();
    }

    itemSelectionChange() {
        this.setGroupCheckboxStates();
        this.itemSelectionsControl.markAsDirty();
    }
}
