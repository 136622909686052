<div class="container-fluid container-fluid--sePadding">
	<app-page-heading iconName="settings" title="User Settings" subTitle="Update your personal user settings">
	</app-page-heading>

	<section *ngIf="serverErrors.length > 0" class="mb-3">
		<app-error-panel class="w-100" [iconName]="'error'" [inputText]="serverErrors[0]" [padding]="'high'">
		</app-error-panel>
	</section>

	<mat-card class="se-card">
		<mat-card-title>
			<div class="d-flex align-items-center">
				<button mat-icon-button class="d-lg-none" (click)="toggleNav()">
					<mat-icon>menu</mat-icon>
				</button>
				<span class="d-none d-lg-inline">SETTINGS</span>
				<span class="d-lg-none">{{ selectedItem.text | uppercase }}</span>
			</div>
		</mat-card-title>

		<mat-drawer-container class="d-none d-lg-block">
			<mat-drawer mode="side" opened>
				<div class="settings-menu">
					<div
						class="settings-menu-item"
						[ngClass]="{ selected: selectedItem === item }"
						*ngFor="let item of menuItems"
						(click)="menuChange(item)">
						<mat-icon>{{ item.icon }}</mat-icon>
						<span>{{ item.text }}</span>
					</div>
				</div>
			</mat-drawer>
			<mat-drawer-content>
				<ng-container *ngTemplateOutlet="settingsContent"></ng-container>
			</mat-drawer-content>
		</mat-drawer-container>
		<mat-drawer-container class="d-block d-lg-none">
			<mat-drawer mode="over" [opened]="navOpened">
				<div class="settings-menu">
					<div
						class="settings-menu-item"
						[ngClass]="{ selected: selectedItem === item }"
						*ngFor="let item of menuItems"
						(click)="menuChange(item)">
						<mat-icon>{{ item.icon }}</mat-icon>
						<span>{{ item.text }}</span>
					</div>
				</div>
			</mat-drawer>
			<mat-drawer-content>
				<ng-container *ngTemplateOutlet="settingsContent"></ng-container>
			</mat-drawer-content>
		</mat-drawer-container>
	</mat-card>
</div>

<ng-template #settingsContent>
	<mat-card-content [ngSwitch]="selectedItem.text">
		<app-password-change *ngSwitchCase="'Change Password'" (showErrors)="showErrors($event)"></app-password-change>
		<app-email-subscriptions
			*ngSwitchCase="'Email Subscriptions'"
			(showErrors)="showErrors($event)"></app-email-subscriptions>
	</mat-card-content>
</ng-template>
