import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
    DashboardDefinitionDTO,
    DashboardFilterRequestDTO,
    DashboardFilterTypeInd,
    DashboardsServiceV2,
    FilterChangeEvent,
    organisationDataSetChange,
    WidgetContainerDTO,
//    WidgetContainerRowDTO,
    WidgetDataSetRequestDTO,
    WidgetDefinitionDTO,
//    WidgetRowDto,
} from '../../../core/services/dashboardsv2.service';
import { HelpModalComponent } from 'src/app/shared/components/modals/help-modal/help-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { of, Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MapDataDto } from 'src/app/shared/models/maps/map-data-dto.model';
import { MapModalComponent } from './modals/map-modal/map-modal.component';
import BaseComponent from 'src/app/core/base/base-component.component';
import { MessageService } from 'src/app/core/services/message.service';
//import { DashboardExport } from '../export/dashboard-export';
//import { WidgetContainer } from '../export/widget-container';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
    selector: 'app-dashboardv2',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [DashboardsServiceV2],
})
export class DashboardComponent extends BaseComponent implements OnInit {
    loadingIndicator: boolean = true;
    dashboardMode: string = 'nodata';
    dashboardTypeInd: number;
    customDashboardId: number;
    locationService: Location;
    vimeoVideoUrl: string = null;
    dashboardService: DashboardsServiceV2;
    dashboardConfig: DashboardDefinitionDTO;
    widgetDataSetRequestDTO: WidgetDataSetRequestDTO = {
        dataSetId: 0,
        organisationId: 0,
        filters: [],
    };

    isProDashboard: boolean = false;

    currentDataSetRequest: organisationDataSetChange;

    private latestOpenedContainer: WidgetContainerDTO;

    @ViewChild('printDiv') printDiv: ElementRef;

    constructor(
        activatedRoute: ActivatedRoute,
        dashboardService: DashboardsServiceV2,
        location: Location,
        private dialog: MatDialog,
        private router: Router,
        private messageService: MessageService
    ) {
        super();
        this.dashboardTypeInd = parseInt(activatedRoute.snapshot.paramMap.get('typeInd'));
        this.customDashboardId = parseInt(activatedRoute.snapshot.paramMap.get('customDashboardId'));
        this.dashboardService = dashboardService;
        this.locationService = location;

        this.isProDashboard = this.dashboardTypeInd == 50;
    }

    /** Load Dashboard Configuration from server */
    ngOnInit(): void {
        this.loadingIndicator = true;
        this.dashboardService
            .getDashboardConfigurationForUser(this.dashboardTypeInd, this.customDashboardId)
            .subscribe((data: DashboardDefinitionDTO) => {
                if (data.organisationDataSets.length > 0) {
                    if (data.organisationDataSets[0].dataSets.length > 0) {
                        this.currentDataSetRequest = {
                            organisationId: data.organisationDataSets[0].id,
                            dataSetId: data.organisationDataSets[0].dataSets[0].id,
                        };

                        this.dashboardMode = 'data';
                        this.widgetDataSetRequestDTO = {
                            organisationId: this.currentDataSetRequest.organisationId,
                            dataSetId: this.currentDataSetRequest.dataSetId,
                            filters: data.filters.map(
                                (x) =>
                                    <DashboardFilterRequestDTO>{
                                        filterTypeInd: x.filterTypeInd,
                                        filterValues: x.selection,
                                    }
                            ),
                        };
                    } else {
                        this.dashboardMode = 'nodata';
                    }
                }

                this.dashboardConfig = data;
                this.loadingIndicator = false;
            });
        this.subscribeToMapDialogButtonClick();
    }

    back() {
        this.locationService.back();
    }

    // Open a new window with formatted echarts ready for printing
    exportPDF() {
        const div = document.getElementById('printDiv');
        html2canvas(div)
            .then((canvas) => {
                const componentBufferX = 25;
                const componentBufferY = 25;
                const componentWidth = div.offsetWidth;
                const componentHeight = div.offsetHeight;

                const orientation = componentWidth >= componentHeight ? 'l' : 'p';

                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation,
                    unit: 'px',
                });
                pdf.internal.pageSize.width = componentWidth;
                pdf.internal.pageSize.height = componentHeight;

                pdf.addImage(
                    imgData,
                    'PNG',
                    componentBufferX,
                    componentBufferY,
                    componentWidth - 50,
                    componentHeight - 50
                );
                return pdf;
            })
            .then((pdf) => {
                pdf.save(`${this.dashboardConfig?.title}.pdf`);
            });

        // const widgets = new Array<WidgetContainer>();

        // // loop the widgets same as html markup and create widgets for export
        // this.dashboardConfig.rows.map((row: WidgetContainerRowDTO) => {
        //     row.containers.map((container: WidgetContainerDTO) => {
        //         container.widgetRows.map((widgetRow: WidgetRowDto) => {
        //             widgetRow.widgets.map((widget: WidgetDefinitionDTO) => {
        //                 widgets.push({
        //                     title: container.title,
        //                     subTitle: container.subTitle,
        //                     widget: widget
        //                 } as WidgetContainer)
        //             });
        //         });
        //     });
        // });

        // const dashboardExport: DashboardExport = {
        //     dashboardTitle: this.dashboardConfig.title,
        //     dashboardSubtitle: this.dashboardConfig.subTitle,
        //     dataSetRequest: this.widgetDataSetRequestDTO,
        //     widgets: widgets,
        // };

        // localStorage.setItem('dashboard-export', JSON.stringify(dashboardExport));
        // const url = this.router.serializeUrl(this.router.createUrlTree(['dashboard-export']));
        // window.open(url, '_blank');
    }

    /** Update the current widget data set request */
    updateWidgetDataSetRequest() {
        // collapse collapseOnUpdate containers
        this.dashboardConfig.rows.forEach((row) =>
            row.containers.forEach((container) => {
                //keep the latest opened container open so it reloads automatically
                if (container.collapseOnUpdate && container != this.latestOpenedContainer) {
                    container.isCollapsed = true;
                }
            })
        );
        this.widgetDataSetRequestDTO = {
            organisationId: this.currentDataSetRequest.organisationId,
            dataSetId: this.currentDataSetRequest.dataSetId,
            filters: this.createFilters(),
        };
    }

    createFilters(): DashboardFilterRequestDTO[] {
        let result: DashboardFilterRequestDTO[] = [];
        this.dashboardConfig.filters.forEach((filter) => {
            switch (filter.filterTypeInd) {
                case DashboardFilterTypeInd.GeographicLevelSelection:
                    result.push({
                        filterTypeInd: filter.filterTypeInd,
                        filterId: filter.filterId,
                        filterValues: filter.selection,
                    });
                    break;

                default:
                    result.push({
                        filterTypeInd: filter.filterTypeInd,
                        filterId: filter.filterId,
                        filterValues: filter.selection,
                    });
                    break;
            }
        });

        return result;
    }

    /** A filter was updated */
    onFilterChanged() {
        this.updateWidgetDataSetRequest();
    }

    /** A data set was changed */
    onDataSetChanged(value: organisationDataSetChange) {
        this.currentDataSetRequest = value;
        this.updateWidgetDataSetRequest();
    }

    /** A Filter Selection Event has been raised by a Widget */
    onFilterChangeRequestFromWidget(widget: any, e: FilterChangeEvent) {
        let newSelection = [];
        newSelection.push(e.value);

        let updateAllowed = true;

        this.dashboardConfig.filters.forEach((filter) => {
            if (filter.filterTypeInd === e.filterTypeInd) {
                if (e.filterTypeInd == DashboardFilterTypeInd.GeographicLevelSelection) {
                    filter.selectionUpdate = newSelection;
                } else if (!filter.minimumSelection || filter.minimumSelection < 2) {
                    filter.selection = newSelection;
                } else {
                    updateAllowed = false;
                }
            }
        });
        if (updateAllowed) {
            this.updateWidgetDataSetRequest();
        }
    }

    showHelpModal(title: string, content: string, vimeoVideoUrl: string) {
        const helpModal = this.dialog.open(HelpModalComponent, {
            panelClass: 'seDialog',
            width: '800px',
            autoFocus: null,
        });
        helpModal.componentInstance.title = title;
        helpModal.componentInstance.content = content;
        helpModal.componentInstance.vimeoVideoUrl = vimeoVideoUrl;
    }

    mapDialogButtonClicked$ = new Subject<{ url: string; widget: WidgetDefinitionDTO }>();
    subscribeToMapDialogButtonClick(): void {
        let widget: WidgetDefinitionDTO;
        // using a subject/switchMap so that only one dialog is loaded at a time (requests are overwritten by subsequent ones)
        this.mapDialogButtonClicked$
            .pipe(
                tap((value: { url: string; widget: WidgetDefinitionDTO }) => {
                    widget = value.widget;
                    widget.loading = true;
                }),
                takeUntil(this.subscriptionComplete),
                // get the widget map data
                switchMap((value: { url: string; widget: WidgetDefinitionDTO }) =>
                    this.dashboardService.getMapDataForWidget(value.url, this.widgetDataSetRequestDTO).pipe(
                        catchError((error) => {
                            this.handleError(error);
                            widget.loading = false;
                            return of(undefined);
                        }),
                        filter((x) => x)
                    )
                )
            )
            .subscribe(
                (mapData: MapDataDto) => {
                    // open the map dialog
                    this.dialog.open(MapModalComponent, {
                        width: '1600px',
                        height: '800px',
                        maxHeight: 'calc(100vh - 96px)',
                        maxWidth: 'calc(100vw - 96px)',
                        panelClass: 'seDialog',
                        data: { mapData: mapData, title: `${widget.title} - Map` },
                    });
                    widget.loading = false;
                },
                () => (widget.loading = false)
            );
    }

    toggleContainerCollapse(container: WidgetContainerDTO, isCollapsed: boolean): void {
        container.isCollapsed = isCollapsed;
        if (!isCollapsed) {
            this.latestOpenedContainer = container;
        }
    }

    handleError(err: any) {
        this.loading = false;
        if (err.error.status == 403) this.router.navigate(['/login']);
        else {
            this.messageService.showError('Something went wrong.');
        }
    }
}