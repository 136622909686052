import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';

import { HomeComponent } from './core/components/home/home.component';
import { LoginComponent } from './core/components/login/login.component';
import { LoginShellComponent } from './core/components/login-shell/login-shell.component';
import { PasswordForgotComponent } from './core/components/password-forgot/password-forgot.component';
import { PasswordResetComponent } from './core/components/password-reset/password-reset.component';
import { ExportComponent } from './modules/dashboardsv2/export/export.component';
import { LayoutComponent } from './core/components/layout/layout.component';
import { SettingsComponent } from './core/components/settings/settings.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', pathMatch: 'full', component: HomeComponent, canActivate: [authGuard] },
            {
                path: 'login',
                component: LoginShellComponent,
                children: [
                    { path: '', component: LoginComponent },
                    { path: 'password-forgot', component: PasswordForgotComponent },
                    { path: 'password-reset/:id', component: PasswordResetComponent },
                ],
            },
            {
                path: 'settings',
                component: SettingsComponent,
                canMatch: [authGuard],
            },
            {
                path: 'admin',
                loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
                canMatch: [authGuard],
            },
            {
                path: 'maps',
                loadChildren: () => import('./modules/maps/maps.module').then((m) => m.MapsModule),
            },
            {
                path: 'dashboardsv2',
                loadChildren: () =>
                    import('./modules/dashboardsv2/dashboardsv2.module').then((m) => m.DashboardsV2Module),
            },
            {
                path: 'academy',
                loadChildren: () => import('./modules/academy/academy.module').then((m) => m.AcademyModule),
            },
            {
                path: 'reports',
                loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
            },
        ],
    },
    {
        path: 'dashboard-export',
        component: ExportComponent,
        canMatch: [authGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
