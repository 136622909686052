<button
	type="{{ type }}"
	class="seBtn {{ bemModifierClasses() }}"
	[class.icon-only]="text.length === 0"
	(click)="btnClicked()"
	[disabled]="showProgress || disabled">
	<span *ngIf="showProgress" class="me-3 spinner-border spinner-border-sm brand-color-dark-grey"></span>
	<mat-icon *ngIf="!showProgress" class="seBtn__icon">{{ icon }}</mat-icon>
	<span *ngIf="text.length > 0" class="seBtn__text">{{ text }}</span>
    <mat-icon *ngIf="showDropDown" class="seBtn__drop-down">arrow_drop_down</mat-icon>
</button>
